import * as ApolloReactHooks from '@aily/saas-graphql-client';
import type * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';

import type {
  ComponentFields_ButtonComponent_Fragment,
  ComponentFields_CardsComponent_Fragment,
  ComponentFields_CashFlowKpiComponent_Fragment,
  ComponentFields_ChartComponent_Fragment,
  ComponentFields_ColumnLayoutComponent_Fragment,
  ComponentFields_ComponentBase_Fragment,
  ComponentFields_DemoBdnlDriversComponent_Fragment,
  ComponentFields_DemoHomeChartComponent_Fragment,
  ComponentFields_DemoHomeMicroChartComponent_Fragment,
  ComponentFields_DemoInsightsBdnlTableComponent_Fragment,
  ComponentFields_DialsComponent_Fragment,
  ComponentFields_EbiHomeCategoriesDialsComponent_Fragment,
  ComponentFields_EbiHomeDetailsChartComponent_Fragment,
  ComponentFields_EbiHomeDetailsChartHeaderComponent_Fragment,
  ComponentFields_EbiHomeDetailsDriversChartComponent_Fragment,
  ComponentFields_EbiHomeDetailsDriversChartHeaderComponent_Fragment,
  ComponentFields_EbiHomeDetailsDriversComponent_Fragment,
  ComponentFields_EbiHomeDetailsRecommendationsComponent_Fragment,
  ComponentFields_FilterComponent_Fragment,
  ComponentFields_FiltersComponent_Fragment,
  ComponentFields_FinGtnOpexAiRecommendationComponent_Fragment,
  ComponentFields_FinHomePageCardsComponent_Fragment,
  ComponentFields_FinLostSalesAnnualTrendTableComponent_Fragment,
  ComponentFields_FinLostSalesChartComponent_Fragment,
  ComponentFields_FinLostSalesChartHeaderComponent_Fragment,
  ComponentFields_FinLostSalesQuarterTableComponent_Fragment,
  ComponentFields_FinLostSalesTableComponent_Fragment,
  ComponentFields_FinOverviewKpisComponent_Fragment,
  ComponentFields_FinOverviewRecommenderComponent_Fragment,
  ComponentFields_FinPnlAiRecommendationComponent_Fragment,
  ComponentFields_GraFacilitatedPathwaysTableComponent_Fragment,
  ComponentFields_GraHomeDialsComponent_Fragment,
  ComponentFields_GraRecommendedPathwaysDetailsComponent_Fragment,
  ComponentFields_GraRecommendedPathwaysTableComponent_Fragment,
  ComponentFields_GridComponent_Fragment,
  ComponentFields_GtmFrequencyChartComponent_Fragment,
  ComponentFields_GtmFrequencyTableComponent_Fragment,
  ComponentFields_GtmHomeDialComponent_Fragment,
  ComponentFields_GtmHomepageCardsComponent_Fragment,
  ComponentFields_GtmImpactChartComponent_Fragment,
  ComponentFields_GtmImpactRecommendationComponent_Fragment,
  ComponentFields_GtmImpactTableComponent_Fragment,
  ComponentFields_GtmImpactTableHeaderComponent_Fragment,
  ComponentFields_GtmInteractionsChartComponent_Fragment,
  ComponentFields_GtmInteractionsTableComponent_Fragment,
  ComponentFields_GtmPulseDetailsChartComponent_Fragment,
  ComponentFields_GtmPulseDetailsChartHeaderComponent_Fragment,
  ComponentFields_GtmPulseDetailsDriversComponent_Fragment,
  ComponentFields_GtmPulseDialComponent_Fragment,
  ComponentFields_GtmPulseLeaderboardListComponent_Fragment,
  ComponentFields_GtmPulseLeaderboardPositionComponent_Fragment,
  ComponentFields_GtmPulseRecommendedActionsComponent_Fragment,
  ComponentFields_GtmReachChartComponent_Fragment,
  ComponentFields_GtmReachTableComponent_Fragment,
  ComponentFields_GtmResourceAllocationTableComponent_Fragment,
  ComponentFields_HeaderRowComponent_Fragment,
  ComponentFields_HeaderSimpleComponent_Fragment,
  ComponentFields_KpiComponent_Fragment,
  ComponentFields_ListComponent_Fragment,
  ComponentFields_MarkdownComponent_Fragment,
  ComponentFields_MicroChartComponent_Fragment,
  ComponentFields_MnsDeviationsDetailsComponent_Fragment,
  ComponentFields_MnsDeviationsDialComponent_Fragment,
  ComponentFields_NavigationTabsComponent_Fragment,
  ComponentFields_PlailistComponent_Fragment,
  ComponentFields_PnlRebatesBreakdownChartComponent_Fragment,
  ComponentFields_PnlRebatesBreakdownChartHeaderComponent_Fragment,
  ComponentFields_PnlRebatesBreakdownTableComponent_Fragment,
  ComponentFields_PnlRebatesInsightsCardsComponent_Fragment,
  ComponentFields_PnlRebatesInsightsHeaderComponent_Fragment,
  ComponentFields_PplHomeCardsComponent_Fragment,
  ComponentFields_PplHomeCategoriesDialsComponent_Fragment,
  ComponentFields_PplHomeDetailsChartComponent_Fragment,
  ComponentFields_PplHomeDetailsChartHeaderComponent_Fragment,
  ComponentFields_PplHomeDetailsDriversChartComponent_Fragment,
  ComponentFields_PplHomeDetailsDriversChartHeaderComponent_Fragment,
  ComponentFields_PplHomeDetailsDriversComponent_Fragment,
  ComponentFields_PplHomeDetailsRecommendationsComponent_Fragment,
  ComponentFields_PplHomeDialComponent_Fragment,
  ComponentFields_ProAgentDetailsComponent_Fragment,
  ComponentFields_ProChatComponent_Fragment,
  ComponentFields_ProDailyAgentComponent_Fragment,
  ComponentFields_ProHomeFeedComponent_Fragment,
  ComponentFields_ProLibraryComponent_Fragment,
  ComponentFields_ProSuperAgentComponent_Fragment,
  ComponentFields_RecommendationsActionsComponent_Fragment,
  ComponentFields_RecommendationsBreakdownComponent_Fragment,
  ComponentFields_RecommenderComponent_Fragment,
  ComponentFields_RndHomeDialsComponent_Fragment,
  ComponentFields_RndInsightsBusinessDevelopmentAndLicensingDriversComponent_Fragment,
  ComponentFields_RndInsightsBusinessDevelopmentAndLicensingTableComponent_Fragment,
  ComponentFields_RndInventoryRecommendationsBreakdownComponent_Fragment,
  ComponentFields_RndInventoryRecommendationsComponent_Fragment,
  ComponentFields_RndInventoryRootCausesComponent_Fragment,
  ComponentFields_RndStudyDesignFormComponent_Fragment,
  ComponentFields_RndStudyDesignsSiteDistributionTableComponent_Fragment,
  ComponentFields_RndStudyDesignsTableComponent_Fragment,
  ComponentFields_RndTrialsAiPosDriversComponent_Fragment,
  ComponentFields_RndTrialsKpiCategoriesComponent_Fragment,
  ComponentFields_RndTrialsKpiDetailTableComponent_Fragment,
  ComponentFields_RndTrialsPatientsEnrollmentHasWhatIfComponent_Fragment,
  ComponentFields_RndTrialsRndInvestmentsPnlTableComponent_Fragment,
  ComponentFields_RndValueOverviewChartComponent_Fragment,
  ComponentFields_RndValueOverviewChartHeaderComponent_Fragment,
  ComponentFields_SpendCmoPulseDialComponent_Fragment,
  ComponentFields_SpendCmoPulseDriversChartComponent_Fragment,
  ComponentFields_SpendCmoPulseDriversListComponent_Fragment,
  ComponentFields_SpendCmoPulseKeyDriversChartComponent_Fragment,
  ComponentFields_SpendCmoPulseKeyDriversListComponent_Fragment,
  ComponentFields_SpendCmoPulseLeaderboardListComponent_Fragment,
  ComponentFields_SpendCmoPulseLeaderboardPositionComponent_Fragment,
  ComponentFields_SpendCmoPulseRecommendedActionsComponent_Fragment,
  ComponentFields_SpendGlocalPulseDialComponent_Fragment,
  ComponentFields_SpendGlocalPulseDriversChartComponent_Fragment,
  ComponentFields_SpendGlocalPulseDriversListComponent_Fragment,
  ComponentFields_SpendGlocalPulseKeyDriversChartComponent_Fragment,
  ComponentFields_SpendGlocalPulseKeyDriversListComponent_Fragment,
  ComponentFields_SpendGlocalPulseLeaderboardListComponent_Fragment,
  ComponentFields_SpendGlocalPulseLeaderboardPositionComponent_Fragment,
  ComponentFields_SpendGlocalPulseRecommendedActionsComponent_Fragment,
  ComponentFields_SpendHomeCardsComponent_Fragment,
  ComponentFields_SpendHomeDialComponent_Fragment,
  ComponentFields_SpendProcurementChartComponent_Fragment,
  ComponentFields_SpendProcurementTableComponent_Fragment,
  ComponentFields_SpendSupplierInsightsTableComponent_Fragment,
  ComponentFields_SpendTrendingComponent_Fragment,
  ComponentFields_StoryListComponent_Fragment,
  ComponentFields_StratPlanPnlAnalyzerChartComponent_Fragment,
  ComponentFields_StratPlanPnlAnalyzerChartHeaderComponent_Fragment,
  ComponentFields_StratPlanPnlAnalyzerTableComponent_Fragment,
  ComponentFields_StratPlanPnlTableComponent_Fragment,
  ComponentFields_StratPlanSalesChartComponent_Fragment,
  ComponentFields_StratPlanSalesChartHeaderComponent_Fragment,
  ComponentFields_StratPlanSalesTableComponent_Fragment,
  ComponentFields_StratPlanSgnaTableComponent_Fragment,
  ComponentFields_SummaryInfoComponent_Fragment,
  ComponentFields_SupplyHomeCardsComponent_Fragment,
  ComponentFields_SupplyHomeDialsComponent_Fragment,
  ComponentFields_SupplyLowInventoryRiskRootCausesComponent_Fragment,
  ComponentFields_SupplyPulseLeaderboardListComponent_Fragment,
  ComponentFields_SupplyShortDatedRootCausesComponent_Fragment,
  ComponentFields_SupportComponent_Fragment,
  ComponentFields_TableComponent_Fragment,
  ComponentFields_TableSwitcherComponent_Fragment,
  ComponentFields_TimelineComponent_Fragment,
  ComponentFields_UserAnalyticsComponent_Fragment,
  ComponentFields_UserPrioritiesComponent_Fragment,
  ComponentFields_VideoComponent_Fragment,
  LinkFieldsFragment,
  UserSettingFieldsFragment,
} from '../../modules/rnd/__generated__/fragments';
import {
  ComponentFieldsFragmentDoc,
  LinkFieldsFragmentDoc,
  UserSettingFieldsFragmentDoc,
} from '../../modules/rnd/__generated__/fragments';
import type * as T from '../../schema/__generated__/types';
import type {
  ChartAxisFields_ChartAxisCategoryViewResult_Fragment,
  ChartAxisFields_ChartAxisPercentViewResult_Fragment,
  ChartAxisFields_ChartAxisValueViewResult_Fragment,
  ChartSeriesFieldsFragment,
  DataViewFields_ButtonDataView_Fragment,
  DataViewFields_CardsDataView_Fragment,
  DataViewFields_ChartDataViewResult_Fragment,
  DataViewFields_DialsDataView_Fragment,
  DataViewFields_GridDataView_Fragment,
  DataViewFields_HeaderRow_Fragment,
  DataViewFields_ListDataView_Fragment,
  DataViewFields_RecommenderDataView_Fragment,
  DataViewFields_SummaryInfoDataView_Fragment,
  DataViewFields_TableDataView_Fragment,
  DialItemFieldsFragment,
  DimensionResultFragmentFragment,
  FilterComponentFieldsFragment,
  FilterFields_SelectFilter_Fragment,
  FilterFields_SelectGroupFilter_Fragment,
  FilterFields_TreeSelectFilter_Fragment,
  FilterValueFields_MultiSelectFilterValue_Fragment,
  FilterValueFields_SelectFilterValue_Fragment,
  FilterValueFields_SelectGroupFilterValue_Fragment,
  KpiCardFieldsFragment,
  KpiStatusOverviewFieldsFragment,
  MetricFieldsFragment,
  MetricListFieldsFragment,
  MicroChartCardFieldsFragment,
  ModuleFieldsFragment,
  NavigationTabsComponentFieldsFragment,
  PlailistsResultFieldsFragment,
  PriorityCategoryFieldsFragment,
  RealFieldsFragment,
  RecommendationsActionsDataViewFieldsFragment,
  RecommendationsBreakdownDataViewFieldsFragment,
  SelectFilterFieldsFragment,
  SelectGroupFilterFieldsFragment,
  SimpleCardFieldsFragment,
  StoryFieldsFragment,
  SummaryInfoItemFieldsFragment,
  SupportComponentFieldsFragment,
  SupportComponentSettingsFieldsFragment,
  TableColumnFieldsFragment,
  TableComponentFieldsFragment,
  TableRowFieldsFragment,
  TableSwitcherComponentFieldsFragment,
  TreeSelectFilterFieldsFragment,
  TreeSelectMdFilterFieldsFragment,
} from './fragments';
import {
  ChartAxisFieldsFragmentDoc,
  ChartSeriesFieldsFragmentDoc,
  DataViewFieldsFragmentDoc,
  DialItemFieldsFragmentDoc,
  DimensionResultFragmentFragmentDoc,
  FilterComponentFieldsFragmentDoc,
  FilterFieldsFragmentDoc,
  FilterValueFieldsFragmentDoc,
  KpiCardFieldsFragmentDoc,
  KpiStatusOverviewFieldsFragmentDoc,
  MetricFieldsFragmentDoc,
  MetricListFieldsFragmentDoc,
  MicroChartCardFieldsFragmentDoc,
  ModuleFieldsFragmentDoc,
  NavigationTabsComponentFieldsFragmentDoc,
  PlailistsResultFieldsFragmentDoc,
  PriorityCategoryFieldsFragmentDoc,
  RealFieldsFragmentDoc,
  RecommendationsActionsDataViewFieldsFragmentDoc,
  RecommendationsBreakdownDataViewFieldsFragmentDoc,
  SelectFilterFieldsFragmentDoc,
  SelectGroupFilterFieldsFragmentDoc,
  SimpleCardFieldsFragmentDoc,
  StoryFieldsFragmentDoc,
  SummaryInfoItemFieldsFragmentDoc,
  SupportComponentFieldsFragmentDoc,
  SupportComponentSettingsFieldsFragmentDoc,
  TableColumnFieldsFragmentDoc,
  TableComponentFieldsFragmentDoc,
  TableRowFieldsFragmentDoc,
  TableSwitcherComponentFieldsFragmentDoc,
  TreeSelectFilterFieldsFragmentDoc,
  TreeSelectMdFilterFieldsFragmentDoc,
} from './fragments';

const defaultOptions = {} as const;
export type GetDataViewQueryVariables = T.Exact<{
  input?: T.InputMaybe<T.DataViewInput>;
}>;

export type GetDataViewQuery = {
  __typename?: 'Query';
  dataView?:
    | ({
        __typename?: 'ButtonDataView';
        label: string;
        filters?: Array<
          | ({
              __typename?: 'MultiSelectFilterValue';
            } & FilterValueFields_MultiSelectFilterValue_Fragment)
          | ({
              __typename?: 'SelectFilterValue';
            } & FilterValueFields_SelectFilterValue_Fragment)
          | ({
              __typename?: 'SelectGroupFilterValue';
            } & FilterValueFields_SelectGroupFilterValue_Fragment)
        > | null;
      } & DataViewFields_ButtonDataView_Fragment)
    | ({
        __typename?: 'CardsDataView';
        title?: string | null;
        annotation?: string | null;
        cards?: Array<
          | ({ __typename?: 'KpiCardResult' } & KpiCardFieldsFragment)
          | ({ __typename?: 'MicroChartCardResult' } & MicroChartCardFieldsFragment)
          | ({ __typename?: 'SimpleCardResult' } & SimpleCardFieldsFragment)
          | null
        > | null;
        editLink?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
      } & DataViewFields_CardsDataView_Fragment)
    | ({
        __typename?: 'ChartDataViewResult';
        renderer?: unknown | null;
        xAxis:
          | ({
              __typename?: 'ChartAxisCategoryViewResult';
            } & ChartAxisFields_ChartAxisCategoryViewResult_Fragment)
          | ({
              __typename?: 'ChartAxisPercentViewResult';
            } & ChartAxisFields_ChartAxisPercentViewResult_Fragment)
          | ({
              __typename?: 'ChartAxisValueViewResult';
            } & ChartAxisFields_ChartAxisValueViewResult_Fragment);
        yAxis:
          | ({
              __typename?: 'ChartAxisCategoryViewResult';
            } & ChartAxisFields_ChartAxisCategoryViewResult_Fragment)
          | ({
              __typename?: 'ChartAxisPercentViewResult';
            } & ChartAxisFields_ChartAxisPercentViewResult_Fragment)
          | ({
              __typename?: 'ChartAxisValueViewResult';
            } & ChartAxisFields_ChartAxisValueViewResult_Fragment);
        series?: Array<
          {
            __typename?: 'ChartSeries';
          } & ChartSeriesFieldsFragment
        > | null;
        filters?: Array<
          | ({
              __typename?: 'MultiSelectFilterValue';
            } & FilterValueFields_MultiSelectFilterValue_Fragment)
          | ({
              __typename?: 'SelectFilterValue';
            } & FilterValueFields_SelectFilterValue_Fragment)
          | ({
              __typename?: 'SelectGroupFilterValue';
            } & FilterValueFields_SelectGroupFilterValue_Fragment)
        > | null;
      } & DataViewFields_ChartDataViewResult_Fragment)
    | ({
        __typename?: 'DialsDataView';
        annotation?: string | null;
        annotationDate?: string | null;
        lastUpdateDate?: string | null;
        items?: Array<{ __typename?: 'DialItem' } & DialItemFieldsFragment> | null;
      } & DataViewFields_DialsDataView_Fragment)
    | ({
        __typename?: 'GridDataView';
        title?: string | null;
        annotation?: string | null;
        lastUpdateDate?: string | null;
        columns?: Array<
          {
            __typename?: 'TableColumnResult';
          } & TableColumnFieldsFragment
        > | null;
        rows?: Array<
          {
            __typename?: 'TableRowResult';
          } & TableRowFieldsFragment
        > | null;
        filters?: Array<
          | ({
              __typename?: 'MultiSelectFilterValue';
            } & FilterValueFields_MultiSelectFilterValue_Fragment)
          | ({
              __typename?: 'SelectFilterValue';
            } & FilterValueFields_SelectFilterValue_Fragment)
          | ({
              __typename?: 'SelectGroupFilterValue';
            } & FilterValueFields_SelectGroupFilterValue_Fragment)
        > | null;
        editLink?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
      } & DataViewFields_GridDataView_Fragment)
    | ({
        __typename?: 'HeaderRow';
        columns?: Array<
          {
            __typename?: 'TableColumnResult';
          } & TableColumnFieldsFragment
        > | null;
        rows?: Array<
          | ({
              __typename?: 'TableRowResult';
            } & TableRowFieldsFragment)
          | null
        > | null;
      } & DataViewFields_HeaderRow_Fragment)
    | ({ __typename?: 'KpiStatusOverview' } & KpiStatusOverviewFieldsFragment)
    | ({
        __typename?: 'ListDataView';
        title?: string | null;
        annotation?: string | null;
        columns?: Array<
          {
            __typename?: 'TableColumnResult';
          } & TableColumnFieldsFragment
        > | null;
        rows?: Array<
          {
            __typename?: 'TableRowResult';
          } & TableRowFieldsFragment
        > | null;
        filters?: Array<
          | ({
              __typename?: 'MultiSelectFilterValue';
            } & FilterValueFields_MultiSelectFilterValue_Fragment)
          | ({
              __typename?: 'SelectFilterValue';
            } & FilterValueFields_SelectFilterValue_Fragment)
          | ({
              __typename?: 'SelectGroupFilterValue';
            } & FilterValueFields_SelectGroupFilterValue_Fragment)
        > | null;
      } & DataViewFields_ListDataView_Fragment)
    | ({
        __typename?: 'RecommendationsActionsDataView';
      } & RecommendationsActionsDataViewFieldsFragment)
    | ({
        __typename?: 'RecommendationsBreakdownDataView';
      } & RecommendationsBreakdownDataViewFieldsFragment)
    | ({
        __typename?: 'RecommenderDataView';
        insights?: Array<
          | ({ __typename?: 'Real' } & RealFieldsFragment)
          | ({ __typename?: 'Story' } & StoryFieldsFragment)
        > | null;
        seeAllLink?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
      } & DataViewFields_RecommenderDataView_Fragment)
    | ({
        __typename?: 'SummaryInfoDataView';
        summaryInfo: {
          __typename?: 'SummaryInfo';
          items?: Array<
            {
              __typename?: 'SummaryInfoItem';
            } & SummaryInfoItemFieldsFragment
          > | null;
        };
      } & DataViewFields_SummaryInfoDataView_Fragment)
    | ({
        __typename?: 'TableDataView';
        title?: string | null;
        annotation?: string | null;
        lastUpdateDate?: string | null;
        columns?: Array<
          {
            __typename?: 'TableColumnResult';
          } & TableColumnFieldsFragment
        > | null;
        rows?: Array<
          | ({
              __typename?: 'TableRowResult';
              children?: Array<
                {
                  __typename?: 'TableRowResult';
                  children?: Array<
                    {
                      __typename?: 'TableRowResult';
                      children?: Array<
                        {
                          __typename?: 'TableRowResult';
                          children?: Array<
                            {
                              __typename?: 'TableRowResult';
                              children?: Array<
                                {
                                  __typename?: 'TableRowResult';
                                } & TableRowFieldsFragment
                              > | null;
                            } & TableRowFieldsFragment
                          > | null;
                        } & TableRowFieldsFragment
                      > | null;
                    } & TableRowFieldsFragment
                  > | null;
                } & TableRowFieldsFragment
              > | null;
            } & TableRowFieldsFragment)
          | null
        > | null;
        filters?: Array<
          | ({
              __typename?: 'MultiSelectFilterValue';
            } & FilterValueFields_MultiSelectFilterValue_Fragment)
          | ({
              __typename?: 'SelectFilterValue';
            } & FilterValueFields_SelectFilterValue_Fragment)
          | ({
              __typename?: 'SelectGroupFilterValue';
            } & FilterValueFields_SelectGroupFilterValue_Fragment)
        > | null;
        editLink?: ({ __typename?: 'Link' } & LinkFieldsFragment) | null;
      } & DataViewFields_TableDataView_Fragment)
    | null;
};

export type GetFilterByIdQueryVariables = T.Exact<{
  id: T.Scalars['Uuid']['input'];
  moduleId?: T.InputMaybe<T.Scalars['Uuid']['input']>;
}>;

export type GetFilterByIdQuery = {
  __typename?: 'Query';
  filterById?:
    | ({ __typename?: 'SelectFilter' } & SelectFilterFieldsFragment &
        FilterFields_SelectFilter_Fragment)
    | ({ __typename?: 'SelectGroupFilter' } & SelectGroupFilterFieldsFragment &
        FilterFields_SelectGroupFilter_Fragment)
    | ({ __typename?: 'TreeSelectFilter' } & TreeSelectFilterFieldsFragment &
        FilterFields_TreeSelectFilter_Fragment)
    | null;
};

export type GetFiltersByIdQueryVariables = T.Exact<{
  ids?: T.InputMaybe<Array<T.Scalars['Uuid']['input']> | T.Scalars['Uuid']['input']>;
  moduleId?: T.InputMaybe<T.Scalars['Uuid']['input']>;
  filters?: T.InputMaybe<Array<T.InputMaybe<T.FilterInput>> | T.InputMaybe<T.FilterInput>>;
  drillId?: T.InputMaybe<T.Scalars['Long']['input']>;
  drillIds?: T.InputMaybe<Array<T.Scalars['Long']['input']> | T.Scalars['Long']['input']>;
  viewCode?: T.InputMaybe<T.Scalars['String']['input']>;
}>;

export type GetFiltersByIdQuery = {
  __typename?: 'Query';
  filtersById?: Array<
    | ({ __typename?: 'SelectFilter' } & SelectFilterFieldsFragment &
        FilterFields_SelectFilter_Fragment)
    | ({ __typename?: 'SelectGroupFilter' } & SelectGroupFilterFieldsFragment &
        FilterFields_SelectGroupFilter_Fragment)
    | ({ __typename?: 'TreeSelectFilter' } & TreeSelectFilterFieldsFragment &
        FilterFields_TreeSelectFilter_Fragment)
    | null
  > | null;
};

export type GetModulesQueryVariables = T.Exact<{ [key: string]: never }>;

export type GetModulesQuery = {
  __typename?: 'Query';
  modules?: Array<
    | ({
        __typename?: 'Module';
      } & ModuleFieldsFragment)
    | null
  > | null;
};

export type GetPageContentQueryVariables = T.Exact<{
  pageId: T.Scalars['Uuid']['input'];
  priorityId?: T.InputMaybe<T.Scalars['Int']['input']>;
}>;

export type GetPageContentQuery = {
  __typename?: 'Query';
  pageContent: {
    __typename?: 'PageContentResult';
    body: Array<
      | ({
          __typename?: 'ButtonComponent';
          filters?: Array<
            { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
          > | null;
        } & ComponentFields_ButtonComponent_Fragment)
      | ({
          __typename?: 'CardsComponent';
        } & ComponentFields_CardsComponent_Fragment)
      | ({
          __typename?: 'ChartComponent';
          drillIds?: Array<number> | null;
          filters?: Array<
            { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
          > | null;
        } & ComponentFields_ChartComponent_Fragment)
      | ({
          __typename?: 'ColumnLayoutComponent';
          headerRow?: Array<
            | ({
                __typename?: 'ButtonComponent';
              } & ComponentFields_ButtonComponent_Fragment)
            | ({
                __typename?: 'CardsComponent';
              } & ComponentFields_CardsComponent_Fragment)
            | ({
                __typename?: 'CashFlowKpiComponent';
              } & ComponentFields_CashFlowKpiComponent_Fragment)
            | ({
                __typename?: 'ChartComponent';
              } & ComponentFields_ChartComponent_Fragment)
            | ({
                __typename?: 'ColumnLayoutComponent';
              } & ComponentFields_ColumnLayoutComponent_Fragment)
            | ({
                __typename?: 'ComponentBase';
              } & ComponentFields_ComponentBase_Fragment)
            | ({
                __typename?: 'DemoBdnlDriversComponent';
              } & ComponentFields_DemoBdnlDriversComponent_Fragment)
            | ({
                __typename?: 'DemoHomeChartComponent';
              } & ComponentFields_DemoHomeChartComponent_Fragment)
            | ({
                __typename?: 'DemoHomeMicroChartComponent';
              } & ComponentFields_DemoHomeMicroChartComponent_Fragment)
            | ({
                __typename?: 'DemoInsightsBdnlTableComponent';
              } & ComponentFields_DemoInsightsBdnlTableComponent_Fragment)
            | ({
                __typename?: 'DialsComponent';
              } & ComponentFields_DialsComponent_Fragment)
            | ({
                __typename?: 'EbiHomeCategoriesDialsComponent';
              } & ComponentFields_EbiHomeCategoriesDialsComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsChartComponent';
              } & ComponentFields_EbiHomeDetailsChartComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsChartHeaderComponent';
              } & ComponentFields_EbiHomeDetailsChartHeaderComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsDriversChartComponent';
              } & ComponentFields_EbiHomeDetailsDriversChartComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsDriversChartHeaderComponent';
              } & ComponentFields_EbiHomeDetailsDriversChartHeaderComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsDriversComponent';
              } & ComponentFields_EbiHomeDetailsDriversComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsRecommendationsComponent';
              } & ComponentFields_EbiHomeDetailsRecommendationsComponent_Fragment)
            | ({
                __typename?: 'FilterComponent';
              } & ComponentFields_FilterComponent_Fragment)
            | ({
                __typename?: 'FiltersComponent';
                filters?: Array<
                  { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
                > | null;
              } & ComponentFields_FiltersComponent_Fragment)
            | ({
                __typename?: 'FinGtnOpexAiRecommendationComponent';
              } & ComponentFields_FinGtnOpexAiRecommendationComponent_Fragment)
            | ({
                __typename?: 'FinHomePageCardsComponent';
              } & ComponentFields_FinHomePageCardsComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesAnnualTrendTableComponent';
              } & ComponentFields_FinLostSalesAnnualTrendTableComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesChartComponent';
              } & ComponentFields_FinLostSalesChartComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesChartHeaderComponent';
              } & ComponentFields_FinLostSalesChartHeaderComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesQuarterTableComponent';
              } & ComponentFields_FinLostSalesQuarterTableComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesTableComponent';
              } & ComponentFields_FinLostSalesTableComponent_Fragment)
            | ({
                __typename?: 'FinOverviewKpisComponent';
              } & ComponentFields_FinOverviewKpisComponent_Fragment)
            | ({
                __typename?: 'FinOverviewRecommenderComponent';
              } & ComponentFields_FinOverviewRecommenderComponent_Fragment)
            | ({
                __typename?: 'FinPnlAiRecommendationComponent';
              } & ComponentFields_FinPnlAiRecommendationComponent_Fragment)
            | ({
                __typename?: 'GraFacilitatedPathwaysTableComponent';
              } & ComponentFields_GraFacilitatedPathwaysTableComponent_Fragment)
            | ({
                __typename?: 'GraHomeDialsComponent';
              } & ComponentFields_GraHomeDialsComponent_Fragment)
            | ({
                __typename?: 'GraRecommendedPathwaysDetailsComponent';
              } & ComponentFields_GraRecommendedPathwaysDetailsComponent_Fragment)
            | ({
                __typename?: 'GraRecommendedPathwaysTableComponent';
              } & ComponentFields_GraRecommendedPathwaysTableComponent_Fragment)
            | ({
                __typename?: 'GridComponent';
              } & ComponentFields_GridComponent_Fragment)
            | ({
                __typename?: 'GtmFrequencyChartComponent';
              } & ComponentFields_GtmFrequencyChartComponent_Fragment)
            | ({
                __typename?: 'GtmFrequencyTableComponent';
              } & ComponentFields_GtmFrequencyTableComponent_Fragment)
            | ({
                __typename?: 'GtmHomeDialComponent';
              } & ComponentFields_GtmHomeDialComponent_Fragment)
            | ({
                __typename?: 'GtmHomepageCardsComponent';
              } & ComponentFields_GtmHomepageCardsComponent_Fragment)
            | ({
                __typename?: 'GtmImpactChartComponent';
              } & ComponentFields_GtmImpactChartComponent_Fragment)
            | ({
                __typename?: 'GtmImpactRecommendationComponent';
              } & ComponentFields_GtmImpactRecommendationComponent_Fragment)
            | ({
                __typename?: 'GtmImpactTableComponent';
              } & ComponentFields_GtmImpactTableComponent_Fragment)
            | ({
                __typename?: 'GtmImpactTableHeaderComponent';
              } & ComponentFields_GtmImpactTableHeaderComponent_Fragment)
            | ({
                __typename?: 'GtmInteractionsChartComponent';
              } & ComponentFields_GtmInteractionsChartComponent_Fragment)
            | ({
                __typename?: 'GtmInteractionsTableComponent';
              } & ComponentFields_GtmInteractionsTableComponent_Fragment)
            | ({
                __typename?: 'GtmPulseDetailsChartComponent';
              } & ComponentFields_GtmPulseDetailsChartComponent_Fragment)
            | ({
                __typename?: 'GtmPulseDetailsChartHeaderComponent';
              } & ComponentFields_GtmPulseDetailsChartHeaderComponent_Fragment)
            | ({
                __typename?: 'GtmPulseDetailsDriversComponent';
              } & ComponentFields_GtmPulseDetailsDriversComponent_Fragment)
            | ({
                __typename?: 'GtmPulseDialComponent';
              } & ComponentFields_GtmPulseDialComponent_Fragment)
            | ({
                __typename?: 'GtmPulseLeaderboardListComponent';
              } & ComponentFields_GtmPulseLeaderboardListComponent_Fragment)
            | ({
                __typename?: 'GtmPulseLeaderboardPositionComponent';
              } & ComponentFields_GtmPulseLeaderboardPositionComponent_Fragment)
            | ({
                __typename?: 'GtmPulseRecommendedActionsComponent';
              } & ComponentFields_GtmPulseRecommendedActionsComponent_Fragment)
            | ({
                __typename?: 'GtmReachChartComponent';
              } & ComponentFields_GtmReachChartComponent_Fragment)
            | ({
                __typename?: 'GtmReachTableComponent';
              } & ComponentFields_GtmReachTableComponent_Fragment)
            | ({
                __typename?: 'GtmResourceAllocationTableComponent';
              } & ComponentFields_GtmResourceAllocationTableComponent_Fragment)
            | ({
                __typename?: 'HeaderRowComponent';
              } & ComponentFields_HeaderRowComponent_Fragment)
            | ({
                __typename?: 'HeaderSimpleComponent';
              } & ComponentFields_HeaderSimpleComponent_Fragment)
            | ({
                __typename?: 'KpiComponent';
              } & ComponentFields_KpiComponent_Fragment)
            | ({
                __typename?: 'ListComponent';
              } & ComponentFields_ListComponent_Fragment)
            | ({
                __typename?: 'MarkdownComponent';
              } & ComponentFields_MarkdownComponent_Fragment)
            | ({
                __typename?: 'MicroChartComponent';
              } & ComponentFields_MicroChartComponent_Fragment)
            | ({
                __typename?: 'MnsDeviationsDetailsComponent';
              } & ComponentFields_MnsDeviationsDetailsComponent_Fragment)
            | ({
                __typename?: 'MnsDeviationsDialComponent';
              } & ComponentFields_MnsDeviationsDialComponent_Fragment)
            | ({
                __typename?: 'NavigationTabsComponent';
              } & ComponentFields_NavigationTabsComponent_Fragment)
            | ({
                __typename?: 'PlailistComponent';
              } & ComponentFields_PlailistComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesBreakdownChartComponent';
              } & ComponentFields_PnlRebatesBreakdownChartComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesBreakdownChartHeaderComponent';
              } & ComponentFields_PnlRebatesBreakdownChartHeaderComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesBreakdownTableComponent';
              } & ComponentFields_PnlRebatesBreakdownTableComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesInsightsCardsComponent';
              } & ComponentFields_PnlRebatesInsightsCardsComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesInsightsHeaderComponent';
              } & ComponentFields_PnlRebatesInsightsHeaderComponent_Fragment)
            | ({
                __typename?: 'PplHomeCardsComponent';
              } & ComponentFields_PplHomeCardsComponent_Fragment)
            | ({
                __typename?: 'PplHomeCategoriesDialsComponent';
              } & ComponentFields_PplHomeCategoriesDialsComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsChartComponent';
              } & ComponentFields_PplHomeDetailsChartComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsChartHeaderComponent';
              } & ComponentFields_PplHomeDetailsChartHeaderComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsDriversChartComponent';
              } & ComponentFields_PplHomeDetailsDriversChartComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsDriversChartHeaderComponent';
              } & ComponentFields_PplHomeDetailsDriversChartHeaderComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsDriversComponent';
              } & ComponentFields_PplHomeDetailsDriversComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsRecommendationsComponent';
              } & ComponentFields_PplHomeDetailsRecommendationsComponent_Fragment)
            | ({
                __typename?: 'PplHomeDialComponent';
              } & ComponentFields_PplHomeDialComponent_Fragment)
            | ({
                __typename?: 'ProAgentDetailsComponent';
              } & ComponentFields_ProAgentDetailsComponent_Fragment)
            | ({ __typename?: 'ProChatComponent' } & ComponentFields_ProChatComponent_Fragment)
            | ({
                __typename?: 'ProDailyAgentComponent';
              } & ComponentFields_ProDailyAgentComponent_Fragment)
            | ({
                __typename?: 'ProHomeFeedComponent';
              } & ComponentFields_ProHomeFeedComponent_Fragment)
            | ({
                __typename?: 'ProLibraryComponent';
              } & ComponentFields_ProLibraryComponent_Fragment)
            | ({
                __typename?: 'ProSuperAgentComponent';
              } & ComponentFields_ProSuperAgentComponent_Fragment)
            | ({
                __typename?: 'RecommendationsActionsComponent';
              } & ComponentFields_RecommendationsActionsComponent_Fragment)
            | ({
                __typename?: 'RecommendationsBreakdownComponent';
              } & ComponentFields_RecommendationsBreakdownComponent_Fragment)
            | ({
                __typename?: 'RecommenderComponent';
              } & ComponentFields_RecommenderComponent_Fragment)
            | ({
                __typename?: 'RndHomeDialsComponent';
              } & ComponentFields_RndHomeDialsComponent_Fragment)
            | ({
                __typename?: 'RndInsightsBusinessDevelopmentAndLicensingDriversComponent';
              } & ComponentFields_RndInsightsBusinessDevelopmentAndLicensingDriversComponent_Fragment)
            | ({
                __typename?: 'RndInsightsBusinessDevelopmentAndLicensingTableComponent';
              } & ComponentFields_RndInsightsBusinessDevelopmentAndLicensingTableComponent_Fragment)
            | ({
                __typename?: 'RndInventoryRecommendationsBreakdownComponent';
              } & ComponentFields_RndInventoryRecommendationsBreakdownComponent_Fragment)
            | ({
                __typename?: 'RndInventoryRecommendationsComponent';
              } & ComponentFields_RndInventoryRecommendationsComponent_Fragment)
            | ({
                __typename?: 'RndInventoryRootCausesComponent';
              } & ComponentFields_RndInventoryRootCausesComponent_Fragment)
            | ({
                __typename?: 'RndStudyDesignFormComponent';
              } & ComponentFields_RndStudyDesignFormComponent_Fragment)
            | ({
                __typename?: 'RndStudyDesignsSiteDistributionTableComponent';
              } & ComponentFields_RndStudyDesignsSiteDistributionTableComponent_Fragment)
            | ({
                __typename?: 'RndStudyDesignsTableComponent';
              } & ComponentFields_RndStudyDesignsTableComponent_Fragment)
            | ({
                __typename?: 'RndTrialsAiPosDriversComponent';
              } & ComponentFields_RndTrialsAiPosDriversComponent_Fragment)
            | ({
                __typename?: 'RndTrialsKpiCategoriesComponent';
              } & ComponentFields_RndTrialsKpiCategoriesComponent_Fragment)
            | ({
                __typename?: 'RndTrialsKpiDetailTableComponent';
              } & ComponentFields_RndTrialsKpiDetailTableComponent_Fragment)
            | ({
                __typename?: 'RndTrialsPatientsEnrollmentHasWhatIfComponent';
              } & ComponentFields_RndTrialsPatientsEnrollmentHasWhatIfComponent_Fragment)
            | ({
                __typename?: 'RndTrialsRndInvestmentsPnlTableComponent';
              } & ComponentFields_RndTrialsRndInvestmentsPnlTableComponent_Fragment)
            | ({
                __typename?: 'RndValueOverviewChartComponent';
              } & ComponentFields_RndValueOverviewChartComponent_Fragment)
            | ({
                __typename?: 'RndValueOverviewChartHeaderComponent';
              } & ComponentFields_RndValueOverviewChartHeaderComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseDialComponent';
              } & ComponentFields_SpendCmoPulseDialComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseDriversChartComponent';
              } & ComponentFields_SpendCmoPulseDriversChartComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseDriversListComponent';
              } & ComponentFields_SpendCmoPulseDriversListComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseKeyDriversChartComponent';
              } & ComponentFields_SpendCmoPulseKeyDriversChartComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseKeyDriversListComponent';
              } & ComponentFields_SpendCmoPulseKeyDriversListComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseLeaderboardListComponent';
              } & ComponentFields_SpendCmoPulseLeaderboardListComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseLeaderboardPositionComponent';
              } & ComponentFields_SpendCmoPulseLeaderboardPositionComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseRecommendedActionsComponent';
              } & ComponentFields_SpendCmoPulseRecommendedActionsComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseDialComponent';
              } & ComponentFields_SpendGlocalPulseDialComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseDriversChartComponent';
              } & ComponentFields_SpendGlocalPulseDriversChartComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseDriversListComponent';
              } & ComponentFields_SpendGlocalPulseDriversListComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseKeyDriversChartComponent';
              } & ComponentFields_SpendGlocalPulseKeyDriversChartComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseKeyDriversListComponent';
              } & ComponentFields_SpendGlocalPulseKeyDriversListComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseLeaderboardListComponent';
              } & ComponentFields_SpendGlocalPulseLeaderboardListComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseLeaderboardPositionComponent';
              } & ComponentFields_SpendGlocalPulseLeaderboardPositionComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseRecommendedActionsComponent';
              } & ComponentFields_SpendGlocalPulseRecommendedActionsComponent_Fragment)
            | ({
                __typename?: 'SpendHomeCardsComponent';
              } & ComponentFields_SpendHomeCardsComponent_Fragment)
            | ({
                __typename?: 'SpendHomeDialComponent';
              } & ComponentFields_SpendHomeDialComponent_Fragment)
            | ({
                __typename?: 'SpendProcurementChartComponent';
              } & ComponentFields_SpendProcurementChartComponent_Fragment)
            | ({
                __typename?: 'SpendProcurementTableComponent';
              } & ComponentFields_SpendProcurementTableComponent_Fragment)
            | ({
                __typename?: 'SpendSupplierInsightsTableComponent';
              } & ComponentFields_SpendSupplierInsightsTableComponent_Fragment)
            | ({
                __typename?: 'SpendTrendingComponent';
              } & ComponentFields_SpendTrendingComponent_Fragment)
            | ({
                __typename?: 'StoryListComponent';
              } & ComponentFields_StoryListComponent_Fragment)
            | ({
                __typename?: 'StratPlanPnlAnalyzerChartComponent';
              } & ComponentFields_StratPlanPnlAnalyzerChartComponent_Fragment)
            | ({
                __typename?: 'StratPlanPnlAnalyzerChartHeaderComponent';
              } & ComponentFields_StratPlanPnlAnalyzerChartHeaderComponent_Fragment)
            | ({
                __typename?: 'StratPlanPnlAnalyzerTableComponent';
              } & ComponentFields_StratPlanPnlAnalyzerTableComponent_Fragment)
            | ({
                __typename?: 'StratPlanPnlTableComponent';
              } & ComponentFields_StratPlanPnlTableComponent_Fragment)
            | ({
                __typename?: 'StratPlanSalesChartComponent';
              } & ComponentFields_StratPlanSalesChartComponent_Fragment)
            | ({
                __typename?: 'StratPlanSalesChartHeaderComponent';
              } & ComponentFields_StratPlanSalesChartHeaderComponent_Fragment)
            | ({
                __typename?: 'StratPlanSalesTableComponent';
              } & ComponentFields_StratPlanSalesTableComponent_Fragment)
            | ({
                __typename?: 'StratPlanSgnaTableComponent';
              } & ComponentFields_StratPlanSgnaTableComponent_Fragment)
            | ({
                __typename?: 'SummaryInfoComponent';
              } & ComponentFields_SummaryInfoComponent_Fragment)
            | ({
                __typename?: 'SupplyHomeCardsComponent';
              } & ComponentFields_SupplyHomeCardsComponent_Fragment)
            | ({
                __typename?: 'SupplyHomeDialsComponent';
              } & ComponentFields_SupplyHomeDialsComponent_Fragment)
            | ({
                __typename?: 'SupplyLowInventoryRiskRootCausesComponent';
              } & ComponentFields_SupplyLowInventoryRiskRootCausesComponent_Fragment)
            | ({
                __typename?: 'SupplyPulseLeaderboardListComponent';
              } & ComponentFields_SupplyPulseLeaderboardListComponent_Fragment)
            | ({
                __typename?: 'SupplyShortDatedRootCausesComponent';
              } & ComponentFields_SupplyShortDatedRootCausesComponent_Fragment)
            | ({
                __typename?: 'SupportComponent';
              } & ComponentFields_SupportComponent_Fragment)
            | ({
                __typename?: 'TableComponent';
              } & ComponentFields_TableComponent_Fragment)
            | ({
                __typename?: 'TableSwitcherComponent';
              } & ComponentFields_TableSwitcherComponent_Fragment)
            | ({
                __typename?: 'TimelineComponent';
              } & ComponentFields_TimelineComponent_Fragment)
            | ({
                __typename?: 'UserAnalyticsComponent';
              } & ComponentFields_UserAnalyticsComponent_Fragment)
            | ({
                __typename?: 'UserPrioritiesComponent';
              } & ComponentFields_UserPrioritiesComponent_Fragment)
            | ({
                __typename?: 'VideoComponent';
              } & ComponentFields_VideoComponent_Fragment)
          > | null;
          firstColumn?: Array<
            | ({
                __typename?: 'ButtonComponent';
              } & ComponentFields_ButtonComponent_Fragment)
            | ({
                __typename?: 'CardsComponent';
              } & ComponentFields_CardsComponent_Fragment)
            | ({
                __typename?: 'CashFlowKpiComponent';
              } & ComponentFields_CashFlowKpiComponent_Fragment)
            | ({
                __typename?: 'ChartComponent';
                filters?: Array<
                  { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
                > | null;
              } & ComponentFields_ChartComponent_Fragment)
            | ({
                __typename?: 'ColumnLayoutComponent';
              } & ComponentFields_ColumnLayoutComponent_Fragment)
            | ({
                __typename?: 'ComponentBase';
              } & ComponentFields_ComponentBase_Fragment)
            | ({
                __typename?: 'DemoBdnlDriversComponent';
              } & ComponentFields_DemoBdnlDriversComponent_Fragment)
            | ({
                __typename?: 'DemoHomeChartComponent';
              } & ComponentFields_DemoHomeChartComponent_Fragment)
            | ({
                __typename?: 'DemoHomeMicroChartComponent';
              } & ComponentFields_DemoHomeMicroChartComponent_Fragment)
            | ({
                __typename?: 'DemoInsightsBdnlTableComponent';
              } & ComponentFields_DemoInsightsBdnlTableComponent_Fragment)
            | ({
                __typename?: 'DialsComponent';
              } & ComponentFields_DialsComponent_Fragment)
            | ({
                __typename?: 'EbiHomeCategoriesDialsComponent';
              } & ComponentFields_EbiHomeCategoriesDialsComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsChartComponent';
              } & ComponentFields_EbiHomeDetailsChartComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsChartHeaderComponent';
              } & ComponentFields_EbiHomeDetailsChartHeaderComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsDriversChartComponent';
              } & ComponentFields_EbiHomeDetailsDriversChartComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsDriversChartHeaderComponent';
              } & ComponentFields_EbiHomeDetailsDriversChartHeaderComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsDriversComponent';
              } & ComponentFields_EbiHomeDetailsDriversComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsRecommendationsComponent';
              } & ComponentFields_EbiHomeDetailsRecommendationsComponent_Fragment)
            | ({
                __typename?: 'FilterComponent';
              } & ComponentFields_FilterComponent_Fragment)
            | ({
                __typename?: 'FiltersComponent';
              } & ComponentFields_FiltersComponent_Fragment)
            | ({
                __typename?: 'FinGtnOpexAiRecommendationComponent';
              } & ComponentFields_FinGtnOpexAiRecommendationComponent_Fragment)
            | ({
                __typename?: 'FinHomePageCardsComponent';
              } & ComponentFields_FinHomePageCardsComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesAnnualTrendTableComponent';
              } & ComponentFields_FinLostSalesAnnualTrendTableComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesChartComponent';
              } & ComponentFields_FinLostSalesChartComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesChartHeaderComponent';
              } & ComponentFields_FinLostSalesChartHeaderComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesQuarterTableComponent';
              } & ComponentFields_FinLostSalesQuarterTableComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesTableComponent';
              } & ComponentFields_FinLostSalesTableComponent_Fragment)
            | ({
                __typename?: 'FinOverviewKpisComponent';
              } & ComponentFields_FinOverviewKpisComponent_Fragment)
            | ({
                __typename?: 'FinOverviewRecommenderComponent';
              } & ComponentFields_FinOverviewRecommenderComponent_Fragment)
            | ({
                __typename?: 'FinPnlAiRecommendationComponent';
              } & ComponentFields_FinPnlAiRecommendationComponent_Fragment)
            | ({
                __typename?: 'GraFacilitatedPathwaysTableComponent';
              } & ComponentFields_GraFacilitatedPathwaysTableComponent_Fragment)
            | ({
                __typename?: 'GraHomeDialsComponent';
              } & ComponentFields_GraHomeDialsComponent_Fragment)
            | ({
                __typename?: 'GraRecommendedPathwaysDetailsComponent';
              } & ComponentFields_GraRecommendedPathwaysDetailsComponent_Fragment)
            | ({
                __typename?: 'GraRecommendedPathwaysTableComponent';
              } & ComponentFields_GraRecommendedPathwaysTableComponent_Fragment)
            | ({
                __typename?: 'GridComponent';
              } & ComponentFields_GridComponent_Fragment)
            | ({
                __typename?: 'GtmFrequencyChartComponent';
              } & ComponentFields_GtmFrequencyChartComponent_Fragment)
            | ({
                __typename?: 'GtmFrequencyTableComponent';
              } & ComponentFields_GtmFrequencyTableComponent_Fragment)
            | ({
                __typename?: 'GtmHomeDialComponent';
              } & ComponentFields_GtmHomeDialComponent_Fragment)
            | ({
                __typename?: 'GtmHomepageCardsComponent';
              } & ComponentFields_GtmHomepageCardsComponent_Fragment)
            | ({
                __typename?: 'GtmImpactChartComponent';
              } & ComponentFields_GtmImpactChartComponent_Fragment)
            | ({
                __typename?: 'GtmImpactRecommendationComponent';
              } & ComponentFields_GtmImpactRecommendationComponent_Fragment)
            | ({
                __typename?: 'GtmImpactTableComponent';
              } & ComponentFields_GtmImpactTableComponent_Fragment)
            | ({
                __typename?: 'GtmImpactTableHeaderComponent';
              } & ComponentFields_GtmImpactTableHeaderComponent_Fragment)
            | ({
                __typename?: 'GtmInteractionsChartComponent';
              } & ComponentFields_GtmInteractionsChartComponent_Fragment)
            | ({
                __typename?: 'GtmInteractionsTableComponent';
              } & ComponentFields_GtmInteractionsTableComponent_Fragment)
            | ({
                __typename?: 'GtmPulseDetailsChartComponent';
              } & ComponentFields_GtmPulseDetailsChartComponent_Fragment)
            | ({
                __typename?: 'GtmPulseDetailsChartHeaderComponent';
              } & ComponentFields_GtmPulseDetailsChartHeaderComponent_Fragment)
            | ({
                __typename?: 'GtmPulseDetailsDriversComponent';
              } & ComponentFields_GtmPulseDetailsDriversComponent_Fragment)
            | ({
                __typename?: 'GtmPulseDialComponent';
              } & ComponentFields_GtmPulseDialComponent_Fragment)
            | ({
                __typename?: 'GtmPulseLeaderboardListComponent';
              } & ComponentFields_GtmPulseLeaderboardListComponent_Fragment)
            | ({
                __typename?: 'GtmPulseLeaderboardPositionComponent';
              } & ComponentFields_GtmPulseLeaderboardPositionComponent_Fragment)
            | ({
                __typename?: 'GtmPulseRecommendedActionsComponent';
              } & ComponentFields_GtmPulseRecommendedActionsComponent_Fragment)
            | ({
                __typename?: 'GtmReachChartComponent';
              } & ComponentFields_GtmReachChartComponent_Fragment)
            | ({
                __typename?: 'GtmReachTableComponent';
              } & ComponentFields_GtmReachTableComponent_Fragment)
            | ({
                __typename?: 'GtmResourceAllocationTableComponent';
              } & ComponentFields_GtmResourceAllocationTableComponent_Fragment)
            | ({
                __typename?: 'HeaderRowComponent';
                variant: T.DisplayVariant;
                filters?: Array<
                  { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
                > | null;
              } & ComponentFields_HeaderRowComponent_Fragment)
            | ({
                __typename?: 'HeaderSimpleComponent';
              } & ComponentFields_HeaderSimpleComponent_Fragment)
            | ({
                __typename?: 'KpiComponent';
              } & ComponentFields_KpiComponent_Fragment)
            | ({
                __typename?: 'ListComponent';
              } & ComponentFields_ListComponent_Fragment)
            | ({
                __typename?: 'MarkdownComponent';
              } & ComponentFields_MarkdownComponent_Fragment)
            | ({
                __typename?: 'MicroChartComponent';
              } & ComponentFields_MicroChartComponent_Fragment)
            | ({
                __typename?: 'MnsDeviationsDetailsComponent';
              } & ComponentFields_MnsDeviationsDetailsComponent_Fragment)
            | ({
                __typename?: 'MnsDeviationsDialComponent';
              } & ComponentFields_MnsDeviationsDialComponent_Fragment)
            | ({
                __typename?: 'NavigationTabsComponent';
              } & ComponentFields_NavigationTabsComponent_Fragment)
            | ({
                __typename?: 'PlailistComponent';
              } & ComponentFields_PlailistComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesBreakdownChartComponent';
              } & ComponentFields_PnlRebatesBreakdownChartComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesBreakdownChartHeaderComponent';
              } & ComponentFields_PnlRebatesBreakdownChartHeaderComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesBreakdownTableComponent';
              } & ComponentFields_PnlRebatesBreakdownTableComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesInsightsCardsComponent';
              } & ComponentFields_PnlRebatesInsightsCardsComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesInsightsHeaderComponent';
              } & ComponentFields_PnlRebatesInsightsHeaderComponent_Fragment)
            | ({
                __typename?: 'PplHomeCardsComponent';
              } & ComponentFields_PplHomeCardsComponent_Fragment)
            | ({
                __typename?: 'PplHomeCategoriesDialsComponent';
              } & ComponentFields_PplHomeCategoriesDialsComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsChartComponent';
              } & ComponentFields_PplHomeDetailsChartComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsChartHeaderComponent';
              } & ComponentFields_PplHomeDetailsChartHeaderComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsDriversChartComponent';
              } & ComponentFields_PplHomeDetailsDriversChartComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsDriversChartHeaderComponent';
              } & ComponentFields_PplHomeDetailsDriversChartHeaderComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsDriversComponent';
              } & ComponentFields_PplHomeDetailsDriversComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsRecommendationsComponent';
              } & ComponentFields_PplHomeDetailsRecommendationsComponent_Fragment)
            | ({
                __typename?: 'PplHomeDialComponent';
              } & ComponentFields_PplHomeDialComponent_Fragment)
            | ({
                __typename?: 'ProAgentDetailsComponent';
              } & ComponentFields_ProAgentDetailsComponent_Fragment)
            | ({ __typename?: 'ProChatComponent' } & ComponentFields_ProChatComponent_Fragment)
            | ({
                __typename?: 'ProDailyAgentComponent';
              } & ComponentFields_ProDailyAgentComponent_Fragment)
            | ({
                __typename?: 'ProHomeFeedComponent';
              } & ComponentFields_ProHomeFeedComponent_Fragment)
            | ({
                __typename?: 'ProLibraryComponent';
              } & ComponentFields_ProLibraryComponent_Fragment)
            | ({
                __typename?: 'ProSuperAgentComponent';
              } & ComponentFields_ProSuperAgentComponent_Fragment)
            | ({
                __typename?: 'RecommendationsActionsComponent';
              } & ComponentFields_RecommendationsActionsComponent_Fragment)
            | ({
                __typename?: 'RecommendationsBreakdownComponent';
              } & ComponentFields_RecommendationsBreakdownComponent_Fragment)
            | ({
                __typename?: 'RecommenderComponent';
              } & ComponentFields_RecommenderComponent_Fragment)
            | ({
                __typename?: 'RndHomeDialsComponent';
              } & ComponentFields_RndHomeDialsComponent_Fragment)
            | ({
                __typename?: 'RndInsightsBusinessDevelopmentAndLicensingDriversComponent';
              } & ComponentFields_RndInsightsBusinessDevelopmentAndLicensingDriversComponent_Fragment)
            | ({
                __typename?: 'RndInsightsBusinessDevelopmentAndLicensingTableComponent';
              } & ComponentFields_RndInsightsBusinessDevelopmentAndLicensingTableComponent_Fragment)
            | ({
                __typename?: 'RndInventoryRecommendationsBreakdownComponent';
              } & ComponentFields_RndInventoryRecommendationsBreakdownComponent_Fragment)
            | ({
                __typename?: 'RndInventoryRecommendationsComponent';
              } & ComponentFields_RndInventoryRecommendationsComponent_Fragment)
            | ({
                __typename?: 'RndInventoryRootCausesComponent';
              } & ComponentFields_RndInventoryRootCausesComponent_Fragment)
            | ({
                __typename?: 'RndStudyDesignFormComponent';
              } & ComponentFields_RndStudyDesignFormComponent_Fragment)
            | ({
                __typename?: 'RndStudyDesignsSiteDistributionTableComponent';
              } & ComponentFields_RndStudyDesignsSiteDistributionTableComponent_Fragment)
            | ({
                __typename?: 'RndStudyDesignsTableComponent';
              } & ComponentFields_RndStudyDesignsTableComponent_Fragment)
            | ({
                __typename?: 'RndTrialsAiPosDriversComponent';
              } & ComponentFields_RndTrialsAiPosDriversComponent_Fragment)
            | ({
                __typename?: 'RndTrialsKpiCategoriesComponent';
              } & ComponentFields_RndTrialsKpiCategoriesComponent_Fragment)
            | ({
                __typename?: 'RndTrialsKpiDetailTableComponent';
              } & ComponentFields_RndTrialsKpiDetailTableComponent_Fragment)
            | ({
                __typename?: 'RndTrialsPatientsEnrollmentHasWhatIfComponent';
              } & ComponentFields_RndTrialsPatientsEnrollmentHasWhatIfComponent_Fragment)
            | ({
                __typename?: 'RndTrialsRndInvestmentsPnlTableComponent';
              } & ComponentFields_RndTrialsRndInvestmentsPnlTableComponent_Fragment)
            | ({
                __typename?: 'RndValueOverviewChartComponent';
              } & ComponentFields_RndValueOverviewChartComponent_Fragment)
            | ({
                __typename?: 'RndValueOverviewChartHeaderComponent';
              } & ComponentFields_RndValueOverviewChartHeaderComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseDialComponent';
              } & ComponentFields_SpendCmoPulseDialComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseDriversChartComponent';
              } & ComponentFields_SpendCmoPulseDriversChartComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseDriversListComponent';
              } & ComponentFields_SpendCmoPulseDriversListComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseKeyDriversChartComponent';
              } & ComponentFields_SpendCmoPulseKeyDriversChartComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseKeyDriversListComponent';
              } & ComponentFields_SpendCmoPulseKeyDriversListComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseLeaderboardListComponent';
              } & ComponentFields_SpendCmoPulseLeaderboardListComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseLeaderboardPositionComponent';
              } & ComponentFields_SpendCmoPulseLeaderboardPositionComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseRecommendedActionsComponent';
              } & ComponentFields_SpendCmoPulseRecommendedActionsComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseDialComponent';
              } & ComponentFields_SpendGlocalPulseDialComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseDriversChartComponent';
              } & ComponentFields_SpendGlocalPulseDriversChartComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseDriversListComponent';
              } & ComponentFields_SpendGlocalPulseDriversListComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseKeyDriversChartComponent';
              } & ComponentFields_SpendGlocalPulseKeyDriversChartComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseKeyDriversListComponent';
              } & ComponentFields_SpendGlocalPulseKeyDriversListComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseLeaderboardListComponent';
              } & ComponentFields_SpendGlocalPulseLeaderboardListComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseLeaderboardPositionComponent';
              } & ComponentFields_SpendGlocalPulseLeaderboardPositionComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseRecommendedActionsComponent';
              } & ComponentFields_SpendGlocalPulseRecommendedActionsComponent_Fragment)
            | ({
                __typename?: 'SpendHomeCardsComponent';
              } & ComponentFields_SpendHomeCardsComponent_Fragment)
            | ({
                __typename?: 'SpendHomeDialComponent';
              } & ComponentFields_SpendHomeDialComponent_Fragment)
            | ({
                __typename?: 'SpendProcurementChartComponent';
              } & ComponentFields_SpendProcurementChartComponent_Fragment)
            | ({
                __typename?: 'SpendProcurementTableComponent';
              } & ComponentFields_SpendProcurementTableComponent_Fragment)
            | ({
                __typename?: 'SpendSupplierInsightsTableComponent';
              } & ComponentFields_SpendSupplierInsightsTableComponent_Fragment)
            | ({
                __typename?: 'SpendTrendingComponent';
              } & ComponentFields_SpendTrendingComponent_Fragment)
            | ({
                __typename?: 'StoryListComponent';
              } & ComponentFields_StoryListComponent_Fragment)
            | ({
                __typename?: 'StratPlanPnlAnalyzerChartComponent';
              } & ComponentFields_StratPlanPnlAnalyzerChartComponent_Fragment)
            | ({
                __typename?: 'StratPlanPnlAnalyzerChartHeaderComponent';
              } & ComponentFields_StratPlanPnlAnalyzerChartHeaderComponent_Fragment)
            | ({
                __typename?: 'StratPlanPnlAnalyzerTableComponent';
              } & ComponentFields_StratPlanPnlAnalyzerTableComponent_Fragment)
            | ({
                __typename?: 'StratPlanPnlTableComponent';
              } & ComponentFields_StratPlanPnlTableComponent_Fragment)
            | ({
                __typename?: 'StratPlanSalesChartComponent';
              } & ComponentFields_StratPlanSalesChartComponent_Fragment)
            | ({
                __typename?: 'StratPlanSalesChartHeaderComponent';
              } & ComponentFields_StratPlanSalesChartHeaderComponent_Fragment)
            | ({
                __typename?: 'StratPlanSalesTableComponent';
              } & ComponentFields_StratPlanSalesTableComponent_Fragment)
            | ({
                __typename?: 'StratPlanSgnaTableComponent';
              } & ComponentFields_StratPlanSgnaTableComponent_Fragment)
            | ({
                __typename?: 'SummaryInfoComponent';
              } & ComponentFields_SummaryInfoComponent_Fragment)
            | ({
                __typename?: 'SupplyHomeCardsComponent';
              } & ComponentFields_SupplyHomeCardsComponent_Fragment)
            | ({
                __typename?: 'SupplyHomeDialsComponent';
              } & ComponentFields_SupplyHomeDialsComponent_Fragment)
            | ({
                __typename?: 'SupplyLowInventoryRiskRootCausesComponent';
              } & ComponentFields_SupplyLowInventoryRiskRootCausesComponent_Fragment)
            | ({
                __typename?: 'SupplyPulseLeaderboardListComponent';
              } & ComponentFields_SupplyPulseLeaderboardListComponent_Fragment)
            | ({
                __typename?: 'SupplyShortDatedRootCausesComponent';
              } & ComponentFields_SupplyShortDatedRootCausesComponent_Fragment)
            | ({
                __typename?: 'SupportComponent';
              } & ComponentFields_SupportComponent_Fragment)
            | ({
                __typename?: 'TableComponent';
              } & ComponentFields_TableComponent_Fragment)
            | ({
                __typename?: 'TableSwitcherComponent';
              } & ComponentFields_TableSwitcherComponent_Fragment)
            | ({
                __typename?: 'TimelineComponent';
              } & ComponentFields_TimelineComponent_Fragment)
            | ({
                __typename?: 'UserAnalyticsComponent';
              } & ComponentFields_UserAnalyticsComponent_Fragment)
            | ({
                __typename?: 'UserPrioritiesComponent';
              } & ComponentFields_UserPrioritiesComponent_Fragment)
            | ({
                __typename?: 'VideoComponent';
              } & ComponentFields_VideoComponent_Fragment)
          > | null;
          secondColumn?: Array<
            | ({
                __typename?: 'ButtonComponent';
              } & ComponentFields_ButtonComponent_Fragment)
            | ({
                __typename?: 'CardsComponent';
              } & ComponentFields_CardsComponent_Fragment)
            | ({
                __typename?: 'CashFlowKpiComponent';
              } & ComponentFields_CashFlowKpiComponent_Fragment)
            | ({
                __typename?: 'ChartComponent';
              } & ComponentFields_ChartComponent_Fragment)
            | ({
                __typename?: 'ColumnLayoutComponent';
              } & ComponentFields_ColumnLayoutComponent_Fragment)
            | ({
                __typename?: 'ComponentBase';
              } & ComponentFields_ComponentBase_Fragment)
            | ({
                __typename?: 'DemoBdnlDriversComponent';
              } & ComponentFields_DemoBdnlDriversComponent_Fragment)
            | ({
                __typename?: 'DemoHomeChartComponent';
              } & ComponentFields_DemoHomeChartComponent_Fragment)
            | ({
                __typename?: 'DemoHomeMicroChartComponent';
              } & ComponentFields_DemoHomeMicroChartComponent_Fragment)
            | ({
                __typename?: 'DemoInsightsBdnlTableComponent';
              } & ComponentFields_DemoInsightsBdnlTableComponent_Fragment)
            | ({
                __typename?: 'DialsComponent';
              } & ComponentFields_DialsComponent_Fragment)
            | ({
                __typename?: 'EbiHomeCategoriesDialsComponent';
              } & ComponentFields_EbiHomeCategoriesDialsComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsChartComponent';
              } & ComponentFields_EbiHomeDetailsChartComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsChartHeaderComponent';
              } & ComponentFields_EbiHomeDetailsChartHeaderComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsDriversChartComponent';
              } & ComponentFields_EbiHomeDetailsDriversChartComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsDriversChartHeaderComponent';
              } & ComponentFields_EbiHomeDetailsDriversChartHeaderComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsDriversComponent';
              } & ComponentFields_EbiHomeDetailsDriversComponent_Fragment)
            | ({
                __typename?: 'EbiHomeDetailsRecommendationsComponent';
              } & ComponentFields_EbiHomeDetailsRecommendationsComponent_Fragment)
            | ({ __typename?: 'FilterComponent' } & FilterComponentFieldsFragment &
                ComponentFields_FilterComponent_Fragment)
            | ({
                __typename?: 'FiltersComponent';
              } & ComponentFields_FiltersComponent_Fragment)
            | ({
                __typename?: 'FinGtnOpexAiRecommendationComponent';
              } & ComponentFields_FinGtnOpexAiRecommendationComponent_Fragment)
            | ({
                __typename?: 'FinHomePageCardsComponent';
              } & ComponentFields_FinHomePageCardsComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesAnnualTrendTableComponent';
              } & ComponentFields_FinLostSalesAnnualTrendTableComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesChartComponent';
              } & ComponentFields_FinLostSalesChartComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesChartHeaderComponent';
              } & ComponentFields_FinLostSalesChartHeaderComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesQuarterTableComponent';
              } & ComponentFields_FinLostSalesQuarterTableComponent_Fragment)
            | ({
                __typename?: 'FinLostSalesTableComponent';
              } & ComponentFields_FinLostSalesTableComponent_Fragment)
            | ({
                __typename?: 'FinOverviewKpisComponent';
              } & ComponentFields_FinOverviewKpisComponent_Fragment)
            | ({
                __typename?: 'FinOverviewRecommenderComponent';
              } & ComponentFields_FinOverviewRecommenderComponent_Fragment)
            | ({
                __typename?: 'FinPnlAiRecommendationComponent';
              } & ComponentFields_FinPnlAiRecommendationComponent_Fragment)
            | ({
                __typename?: 'GraFacilitatedPathwaysTableComponent';
              } & ComponentFields_GraFacilitatedPathwaysTableComponent_Fragment)
            | ({
                __typename?: 'GraHomeDialsComponent';
              } & ComponentFields_GraHomeDialsComponent_Fragment)
            | ({
                __typename?: 'GraRecommendedPathwaysDetailsComponent';
              } & ComponentFields_GraRecommendedPathwaysDetailsComponent_Fragment)
            | ({
                __typename?: 'GraRecommendedPathwaysTableComponent';
              } & ComponentFields_GraRecommendedPathwaysTableComponent_Fragment)
            | ({
                __typename?: 'GridComponent';
              } & ComponentFields_GridComponent_Fragment)
            | ({
                __typename?: 'GtmFrequencyChartComponent';
              } & ComponentFields_GtmFrequencyChartComponent_Fragment)
            | ({
                __typename?: 'GtmFrequencyTableComponent';
              } & ComponentFields_GtmFrequencyTableComponent_Fragment)
            | ({
                __typename?: 'GtmHomeDialComponent';
              } & ComponentFields_GtmHomeDialComponent_Fragment)
            | ({
                __typename?: 'GtmHomepageCardsComponent';
              } & ComponentFields_GtmHomepageCardsComponent_Fragment)
            | ({
                __typename?: 'GtmImpactChartComponent';
              } & ComponentFields_GtmImpactChartComponent_Fragment)
            | ({
                __typename?: 'GtmImpactRecommendationComponent';
              } & ComponentFields_GtmImpactRecommendationComponent_Fragment)
            | ({
                __typename?: 'GtmImpactTableComponent';
              } & ComponentFields_GtmImpactTableComponent_Fragment)
            | ({
                __typename?: 'GtmImpactTableHeaderComponent';
              } & ComponentFields_GtmImpactTableHeaderComponent_Fragment)
            | ({
                __typename?: 'GtmInteractionsChartComponent';
              } & ComponentFields_GtmInteractionsChartComponent_Fragment)
            | ({
                __typename?: 'GtmInteractionsTableComponent';
              } & ComponentFields_GtmInteractionsTableComponent_Fragment)
            | ({
                __typename?: 'GtmPulseDetailsChartComponent';
              } & ComponentFields_GtmPulseDetailsChartComponent_Fragment)
            | ({
                __typename?: 'GtmPulseDetailsChartHeaderComponent';
              } & ComponentFields_GtmPulseDetailsChartHeaderComponent_Fragment)
            | ({
                __typename?: 'GtmPulseDetailsDriversComponent';
              } & ComponentFields_GtmPulseDetailsDriversComponent_Fragment)
            | ({
                __typename?: 'GtmPulseDialComponent';
              } & ComponentFields_GtmPulseDialComponent_Fragment)
            | ({
                __typename?: 'GtmPulseLeaderboardListComponent';
              } & ComponentFields_GtmPulseLeaderboardListComponent_Fragment)
            | ({
                __typename?: 'GtmPulseLeaderboardPositionComponent';
              } & ComponentFields_GtmPulseLeaderboardPositionComponent_Fragment)
            | ({
                __typename?: 'GtmPulseRecommendedActionsComponent';
              } & ComponentFields_GtmPulseRecommendedActionsComponent_Fragment)
            | ({
                __typename?: 'GtmReachChartComponent';
              } & ComponentFields_GtmReachChartComponent_Fragment)
            | ({
                __typename?: 'GtmReachTableComponent';
              } & ComponentFields_GtmReachTableComponent_Fragment)
            | ({
                __typename?: 'GtmResourceAllocationTableComponent';
              } & ComponentFields_GtmResourceAllocationTableComponent_Fragment)
            | ({
                __typename?: 'HeaderRowComponent';
              } & ComponentFields_HeaderRowComponent_Fragment)
            | ({
                __typename?: 'HeaderSimpleComponent';
              } & ComponentFields_HeaderSimpleComponent_Fragment)
            | ({
                __typename?: 'KpiComponent';
              } & ComponentFields_KpiComponent_Fragment)
            | ({
                __typename?: 'ListComponent';
              } & ComponentFields_ListComponent_Fragment)
            | ({
                __typename?: 'MarkdownComponent';
              } & ComponentFields_MarkdownComponent_Fragment)
            | ({
                __typename?: 'MicroChartComponent';
              } & ComponentFields_MicroChartComponent_Fragment)
            | ({
                __typename?: 'MnsDeviationsDetailsComponent';
              } & ComponentFields_MnsDeviationsDetailsComponent_Fragment)
            | ({
                __typename?: 'MnsDeviationsDialComponent';
              } & ComponentFields_MnsDeviationsDialComponent_Fragment)
            | ({
                __typename?: 'NavigationTabsComponent';
              } & ComponentFields_NavigationTabsComponent_Fragment)
            | ({
                __typename?: 'PlailistComponent';
              } & ComponentFields_PlailistComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesBreakdownChartComponent';
              } & ComponentFields_PnlRebatesBreakdownChartComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesBreakdownChartHeaderComponent';
              } & ComponentFields_PnlRebatesBreakdownChartHeaderComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesBreakdownTableComponent';
              } & ComponentFields_PnlRebatesBreakdownTableComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesInsightsCardsComponent';
              } & ComponentFields_PnlRebatesInsightsCardsComponent_Fragment)
            | ({
                __typename?: 'PnlRebatesInsightsHeaderComponent';
              } & ComponentFields_PnlRebatesInsightsHeaderComponent_Fragment)
            | ({
                __typename?: 'PplHomeCardsComponent';
              } & ComponentFields_PplHomeCardsComponent_Fragment)
            | ({
                __typename?: 'PplHomeCategoriesDialsComponent';
              } & ComponentFields_PplHomeCategoriesDialsComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsChartComponent';
              } & ComponentFields_PplHomeDetailsChartComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsChartHeaderComponent';
              } & ComponentFields_PplHomeDetailsChartHeaderComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsDriversChartComponent';
              } & ComponentFields_PplHomeDetailsDriversChartComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsDriversChartHeaderComponent';
              } & ComponentFields_PplHomeDetailsDriversChartHeaderComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsDriversComponent';
              } & ComponentFields_PplHomeDetailsDriversComponent_Fragment)
            | ({
                __typename?: 'PplHomeDetailsRecommendationsComponent';
              } & ComponentFields_PplHomeDetailsRecommendationsComponent_Fragment)
            | ({
                __typename?: 'PplHomeDialComponent';
              } & ComponentFields_PplHomeDialComponent_Fragment)
            | ({
                __typename?: 'ProAgentDetailsComponent';
              } & ComponentFields_ProAgentDetailsComponent_Fragment)
            | ({ __typename?: 'ProChatComponent' } & ComponentFields_ProChatComponent_Fragment)
            | ({
                __typename?: 'ProDailyAgentComponent';
              } & ComponentFields_ProDailyAgentComponent_Fragment)
            | ({
                __typename?: 'ProHomeFeedComponent';
              } & ComponentFields_ProHomeFeedComponent_Fragment)
            | ({
                __typename?: 'ProLibraryComponent';
              } & ComponentFields_ProLibraryComponent_Fragment)
            | ({
                __typename?: 'ProSuperAgentComponent';
              } & ComponentFields_ProSuperAgentComponent_Fragment)
            | ({
                __typename?: 'RecommendationsActionsComponent';
                code: string;
                id: string;
                filters?: Array<
                  { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
                > | null;
              } & ComponentFields_RecommendationsActionsComponent_Fragment)
            | ({
                __typename?: 'RecommendationsBreakdownComponent';
              } & ComponentFields_RecommendationsBreakdownComponent_Fragment)
            | ({
                __typename?: 'RecommenderComponent';
              } & ComponentFields_RecommenderComponent_Fragment)
            | ({
                __typename?: 'RndHomeDialsComponent';
              } & ComponentFields_RndHomeDialsComponent_Fragment)
            | ({
                __typename?: 'RndInsightsBusinessDevelopmentAndLicensingDriversComponent';
              } & ComponentFields_RndInsightsBusinessDevelopmentAndLicensingDriversComponent_Fragment)
            | ({
                __typename?: 'RndInsightsBusinessDevelopmentAndLicensingTableComponent';
              } & ComponentFields_RndInsightsBusinessDevelopmentAndLicensingTableComponent_Fragment)
            | ({
                __typename?: 'RndInventoryRecommendationsBreakdownComponent';
              } & ComponentFields_RndInventoryRecommendationsBreakdownComponent_Fragment)
            | ({
                __typename?: 'RndInventoryRecommendationsComponent';
              } & ComponentFields_RndInventoryRecommendationsComponent_Fragment)
            | ({
                __typename?: 'RndInventoryRootCausesComponent';
              } & ComponentFields_RndInventoryRootCausesComponent_Fragment)
            | ({
                __typename?: 'RndStudyDesignFormComponent';
              } & ComponentFields_RndStudyDesignFormComponent_Fragment)
            | ({
                __typename?: 'RndStudyDesignsSiteDistributionTableComponent';
              } & ComponentFields_RndStudyDesignsSiteDistributionTableComponent_Fragment)
            | ({
                __typename?: 'RndStudyDesignsTableComponent';
              } & ComponentFields_RndStudyDesignsTableComponent_Fragment)
            | ({
                __typename?: 'RndTrialsAiPosDriversComponent';
              } & ComponentFields_RndTrialsAiPosDriversComponent_Fragment)
            | ({
                __typename?: 'RndTrialsKpiCategoriesComponent';
              } & ComponentFields_RndTrialsKpiCategoriesComponent_Fragment)
            | ({
                __typename?: 'RndTrialsKpiDetailTableComponent';
              } & ComponentFields_RndTrialsKpiDetailTableComponent_Fragment)
            | ({
                __typename?: 'RndTrialsPatientsEnrollmentHasWhatIfComponent';
              } & ComponentFields_RndTrialsPatientsEnrollmentHasWhatIfComponent_Fragment)
            | ({
                __typename?: 'RndTrialsRndInvestmentsPnlTableComponent';
              } & ComponentFields_RndTrialsRndInvestmentsPnlTableComponent_Fragment)
            | ({
                __typename?: 'RndValueOverviewChartComponent';
              } & ComponentFields_RndValueOverviewChartComponent_Fragment)
            | ({
                __typename?: 'RndValueOverviewChartHeaderComponent';
              } & ComponentFields_RndValueOverviewChartHeaderComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseDialComponent';
              } & ComponentFields_SpendCmoPulseDialComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseDriversChartComponent';
              } & ComponentFields_SpendCmoPulseDriversChartComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseDriversListComponent';
              } & ComponentFields_SpendCmoPulseDriversListComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseKeyDriversChartComponent';
              } & ComponentFields_SpendCmoPulseKeyDriversChartComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseKeyDriversListComponent';
              } & ComponentFields_SpendCmoPulseKeyDriversListComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseLeaderboardListComponent';
              } & ComponentFields_SpendCmoPulseLeaderboardListComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseLeaderboardPositionComponent';
              } & ComponentFields_SpendCmoPulseLeaderboardPositionComponent_Fragment)
            | ({
                __typename?: 'SpendCmoPulseRecommendedActionsComponent';
              } & ComponentFields_SpendCmoPulseRecommendedActionsComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseDialComponent';
              } & ComponentFields_SpendGlocalPulseDialComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseDriversChartComponent';
              } & ComponentFields_SpendGlocalPulseDriversChartComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseDriversListComponent';
              } & ComponentFields_SpendGlocalPulseDriversListComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseKeyDriversChartComponent';
              } & ComponentFields_SpendGlocalPulseKeyDriversChartComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseKeyDriversListComponent';
              } & ComponentFields_SpendGlocalPulseKeyDriversListComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseLeaderboardListComponent';
              } & ComponentFields_SpendGlocalPulseLeaderboardListComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseLeaderboardPositionComponent';
              } & ComponentFields_SpendGlocalPulseLeaderboardPositionComponent_Fragment)
            | ({
                __typename?: 'SpendGlocalPulseRecommendedActionsComponent';
              } & ComponentFields_SpendGlocalPulseRecommendedActionsComponent_Fragment)
            | ({
                __typename?: 'SpendHomeCardsComponent';
              } & ComponentFields_SpendHomeCardsComponent_Fragment)
            | ({
                __typename?: 'SpendHomeDialComponent';
              } & ComponentFields_SpendHomeDialComponent_Fragment)
            | ({
                __typename?: 'SpendProcurementChartComponent';
              } & ComponentFields_SpendProcurementChartComponent_Fragment)
            | ({
                __typename?: 'SpendProcurementTableComponent';
              } & ComponentFields_SpendProcurementTableComponent_Fragment)
            | ({
                __typename?: 'SpendSupplierInsightsTableComponent';
              } & ComponentFields_SpendSupplierInsightsTableComponent_Fragment)
            | ({
                __typename?: 'SpendTrendingComponent';
              } & ComponentFields_SpendTrendingComponent_Fragment)
            | ({
                __typename?: 'StoryListComponent';
              } & ComponentFields_StoryListComponent_Fragment)
            | ({
                __typename?: 'StratPlanPnlAnalyzerChartComponent';
              } & ComponentFields_StratPlanPnlAnalyzerChartComponent_Fragment)
            | ({
                __typename?: 'StratPlanPnlAnalyzerChartHeaderComponent';
              } & ComponentFields_StratPlanPnlAnalyzerChartHeaderComponent_Fragment)
            | ({
                __typename?: 'StratPlanPnlAnalyzerTableComponent';
              } & ComponentFields_StratPlanPnlAnalyzerTableComponent_Fragment)
            | ({
                __typename?: 'StratPlanPnlTableComponent';
              } & ComponentFields_StratPlanPnlTableComponent_Fragment)
            | ({
                __typename?: 'StratPlanSalesChartComponent';
              } & ComponentFields_StratPlanSalesChartComponent_Fragment)
            | ({
                __typename?: 'StratPlanSalesChartHeaderComponent';
              } & ComponentFields_StratPlanSalesChartHeaderComponent_Fragment)
            | ({
                __typename?: 'StratPlanSalesTableComponent';
              } & ComponentFields_StratPlanSalesTableComponent_Fragment)
            | ({
                __typename?: 'StratPlanSgnaTableComponent';
              } & ComponentFields_StratPlanSgnaTableComponent_Fragment)
            | ({
                __typename?: 'SummaryInfoComponent';
              } & ComponentFields_SummaryInfoComponent_Fragment)
            | ({
                __typename?: 'SupplyHomeCardsComponent';
              } & ComponentFields_SupplyHomeCardsComponent_Fragment)
            | ({
                __typename?: 'SupplyHomeDialsComponent';
              } & ComponentFields_SupplyHomeDialsComponent_Fragment)
            | ({
                __typename?: 'SupplyLowInventoryRiskRootCausesComponent';
              } & ComponentFields_SupplyLowInventoryRiskRootCausesComponent_Fragment)
            | ({
                __typename?: 'SupplyPulseLeaderboardListComponent';
              } & ComponentFields_SupplyPulseLeaderboardListComponent_Fragment)
            | ({
                __typename?: 'SupplyShortDatedRootCausesComponent';
              } & ComponentFields_SupplyShortDatedRootCausesComponent_Fragment)
            | ({
                __typename?: 'SupportComponent';
              } & ComponentFields_SupportComponent_Fragment)
            | ({ __typename?: 'TableComponent' } & TableComponentFieldsFragment &
                ComponentFields_TableComponent_Fragment)
            | ({
                __typename?: 'TableSwitcherComponent';
              } & ComponentFields_TableSwitcherComponent_Fragment)
            | ({
                __typename?: 'TimelineComponent';
              } & ComponentFields_TimelineComponent_Fragment)
            | ({
                __typename?: 'UserAnalyticsComponent';
              } & ComponentFields_UserAnalyticsComponent_Fragment)
            | ({
                __typename?: 'UserPrioritiesComponent';
              } & ComponentFields_UserPrioritiesComponent_Fragment)
            | ({
                __typename?: 'VideoComponent';
              } & ComponentFields_VideoComponent_Fragment)
          > | null;
        } & ComponentFields_ColumnLayoutComponent_Fragment)
      | ({
          __typename?: 'DialsComponent';
        } & ComponentFields_DialsComponent_Fragment)
      | ({ __typename?: 'FilterComponent' } & FilterComponentFieldsFragment)
      | ({
          __typename?: 'FiltersComponent';
          filters?: Array<
            { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
          > | null;
        } & ComponentFields_FiltersComponent_Fragment)
      | ({
          __typename?: 'GridComponent';
          filters?: Array<
            { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
          > | null;
        } & ComponentFields_GridComponent_Fragment)
      | ({
          __typename?: 'HeaderRowComponent';
          drillIds?: Array<number> | null;
          variant: T.DisplayVariant;
          filters?: Array<
            { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
          > | null;
        } & ComponentFields_HeaderRowComponent_Fragment)
      | ({
          __typename?: 'KpiComponent';
          filters?: Array<
            { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
          > | null;
        } & ComponentFields_KpiComponent_Fragment)
      | ({
          __typename?: 'ListComponent';
          filters?: Array<
            { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
          > | null;
        } & ComponentFields_ListComponent_Fragment)
      | ({
          __typename?: 'NavigationTabsComponent';
        } & NavigationTabsComponentFieldsFragment)
      | ({
          __typename?: 'PlailistComponent';
        } & ComponentFields_PlailistComponent_Fragment)
      | {
          __typename?: 'RecommendationsBreakdownComponent';
          id: string;
          componentType: T.ComponentType;
          icon: T.ComponentIcon;
          viewType: T.ViewType;
          filters?: Array<
            { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
          > | null;
        }
      | ({
          __typename?: 'RecommenderComponent';
        } & ComponentFields_RecommenderComponent_Fragment)
      | ({
          __typename?: 'StoryListComponent';
        } & ComponentFields_StoryListComponent_Fragment)
      | ({
          __typename?: 'SummaryInfoComponent';
          filters?: Array<
            { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
          > | null;
        } & ComponentFields_SummaryInfoComponent_Fragment)
      | ({ __typename?: 'TableComponent' } & TableComponentFieldsFragment)
      | ({
          __typename?: 'TableSwitcherComponent';
        } & TableSwitcherComponentFieldsFragment)
      | ({
          __typename?: 'TimelineComponent';
        } & ComponentFields_TimelineComponent_Fragment)
      | ({
          __typename?: 'UserAnalyticsComponent';
          filters?: Array<
            { __typename?: 'FilterComponent' } & FilterComponentFieldsFragment
          > | null;
        } & ComponentFields_UserAnalyticsComponent_Fragment)
      | ({
          __typename?: 'UserPrioritiesComponent';
        } & ComponentFields_UserPrioritiesComponent_Fragment)
    >;
  };
};

export type GetPlailistsQueryVariables = T.Exact<{
  input: T.PlailistsQueryInput;
}>;

export type GetPlailistsQuery = {
  __typename?: 'Query';
  plailists?:
    | ({
        __typename?: 'PlailistsResult';
      } & PlailistsResultFieldsFragment)
    | null;
};

export type GetPrioritiesQueryVariables = T.Exact<{
  input: T.PrioritiesInput;
}>;

export type GetPrioritiesQuery = {
  __typename?: 'Query';
  priorities: Array<
    {
      __typename?: 'PriorityCategoryResult';
    } & PriorityCategoryFieldsFragment
  >;
};

export type GetPriorityFilterQueryVariables = T.Exact<{
  moduleId: T.Scalars['Uuid']['input'];
}>;

export type GetPriorityFilterQuery = {
  __typename?: 'Query';
  priorityFilter?:
    | ({ __typename?: 'SelectFilter' } & SelectFilterFieldsFragment &
        FilterFields_SelectFilter_Fragment)
    | ({
        __typename?: 'SelectGroupFilter';
      } & FilterFields_SelectGroupFilter_Fragment)
    | ({ __typename?: 'TreeSelectFilter' } & TreeSelectFilterFieldsFragment &
        FilterFields_TreeSelectFilter_Fragment)
    | null;
};

export type GetStoriesQueryVariables = T.Exact<{
  input: T.StoriesQueryInput;
}>;

export type GetStoriesQuery = {
  __typename?: 'Query';
  stories?: Array<
    | ({ __typename?: 'Real' } & RealFieldsFragment)
    | ({ __typename?: 'Story' } & StoryFieldsFragment)
    | null
  > | null;
};

export type GetStoriesByPlailistQueryVariables = T.Exact<{
  input: T.PlailistStoriesInput;
}>;

export type GetStoriesByPlailistQuery = {
  __typename?: 'Query';
  storiesByPlailist?: Array<
    | ({
        __typename?: 'Story';
      } & StoryFieldsFragment)
    | null
  > | null;
};

export type GetStoryByIdQueryVariables = T.Exact<{
  storyId: T.Scalars['Uuid']['input'];
}>;

export type GetStoryByIdQuery = {
  __typename?: 'Query';
  storyById?: ({ __typename?: 'Story' } & StoryFieldsFragment) | null;
};

export type GetUserAnalyticsQueryVariables = T.Exact<{
  input: T.UserAnalyticsInput;
}>;

export type GetUserAnalyticsQuery = {
  __typename?: 'Query';
  userAnalytics?: {
    __typename?: 'UserAnalytics';
    timeFrame?: string | null;
    headerMetrics?: Array<
      | ({
          __typename?: 'Metric';
        } & MetricFieldsFragment)
      | null
    > | null;
    metricLists?: Array<
      | ({
          __typename?: 'MetricList';
        } & MetricListFieldsFragment)
      | null
    > | null;
  } | null;
};

export type GetUserPrioritiesQueryVariables = T.Exact<{
  input: T.PrioritiesInput;
}>;

export type GetUserPrioritiesQuery = {
  __typename?: 'Query';
  userPriorities: Array<
    {
      __typename?: 'PriorityCategoryResult';
    } & PriorityCategoryFieldsFragment
  >;
};

export type GetUserSettingsQueryVariables = T.Exact<{
  input: T.GetUserSettingsInput;
}>;

export type GetUserSettingsQuery = {
  __typename?: 'Query';
  userSettings: Array<
    {
      __typename?: 'UserSetting';
    } & UserSettingFieldsFragment
  >;
};

export type GetDimensionFilterQueryVariables = T.Exact<{
  moduleId: T.Scalars['Uuid']['input'];
  priorityId: T.Scalars['Int']['input'];
}>;

export type GetDimensionFilterQuery = {
  __typename?: 'Query';
  dimensionFilter?:
    | ({ __typename: 'SelectFilter' } & SelectFilterFieldsFragment &
        FilterFields_SelectFilter_Fragment)
    | ({
        __typename: 'SelectGroupFilter';
      } & FilterFields_SelectGroupFilter_Fragment)
    | ({ __typename: 'TreeSelectFilter' } & TreeSelectMdFilterFieldsFragment &
        FilterFields_TreeSelectFilter_Fragment)
    | null;
};

export type GetInboxQueryVariables = T.Exact<{ [key: string]: never }>;

export type GetInboxQuery = {
  __typename?: 'Query';
  inbox: {
    __typename?: 'InboxResult';
    messages?: {
      __typename?: 'MessagesResult';
      sharedLinks: Array<{
        __typename?: 'SharedLink';
        id: string;
        title: string;
        subtitle?: string | null;
        moduleCode: T.ModuleCode;
        sharedBy: string;
        link: string;
        isFollowed: boolean;
        contentType: T.InboxContentType;
        isExpired: boolean;
        sentAt?: string | null;
        tag?: {
          __typename?: 'InboxItemTag';
          type: T.TagType;
          message: string;
          isDefault: boolean;
        } | null;
      }>;
    } | null;
    decisions?: Array<{
      __typename?: 'DecisionItem';
      title: string;
      contentType: T.DecisionContentType;
      isExpired: boolean;
    } | null> | null;
    yourBrain?: Array<{
      __typename?: 'YourBrainItem';
      resourceId: string;
      title: string;
      contentType: T.InboxContentType;
      moduleCode: T.ModuleCode;
      link: string;
      createdAt?: string | null;
      isExpired: boolean;
    } | null> | null;
  };
};

export type GetDimensionsQueryVariables = T.Exact<{
  input: T.DimensionsInput;
}>;

export type GetDimensionsQuery = {
  __typename?: 'Query';
  dimensions: Array<
    {
      __typename?: 'DimensionResult';
    } & DimensionResultFragmentFragment
  >;
};

export type GetPlailistStoriesQueryVariables = T.Exact<{
  input: T.PlailistStoriesInput;
}>;

export type GetPlailistStoriesQuery = {
  __typename?: 'Query';
  storiesByPlailist?: Array<
    | ({
        __typename?: 'Story';
      } & StoryFieldsFragment)
    | null
  > | null;
};

export type GetSupportPageContentQueryVariables = T.Exact<{
  pageId: T.Scalars['Uuid']['input'];
  priorityId?: T.InputMaybe<T.Scalars['Int']['input']>;
}>;

export type GetSupportPageContentQuery = {
  __typename?: 'Query';
  pageContent: {
    __typename?: 'PageContentResult';
    body: Array<
      {
        __typename?: 'SupportComponent';
        settings?:
          | ({
              __typename?: 'SupportSettings';
            } & SupportComponentSettingsFieldsFragment)
          | null;
      } & SupportComponentFieldsFragment
    >;
  };
};

export type SiteConfigurationQueryVariables = T.Exact<{ [key: string]: never }>;

export type SiteConfigurationQuery = {
  __typename?: 'Query';
  siteConfiguration?: {
    __typename?: 'SiteConfiguration';
    version: string;
  } | null;
};

export const GetDataViewDocument = gql`
  query GetDataView($input: DataViewInput) {
    dataView(input: $input) {
      ... on TableDataView {
        ...DataViewFields
        title
        annotation
        columns {
          ...TableColumnFields
        }
        rows {
          ...TableRowFields
          children {
            ...TableRowFields
            children {
              ...TableRowFields
              children {
                ...TableRowFields
                children {
                  ...TableRowFields
                  children {
                    ...TableRowFields
                  }
                }
              }
            }
          }
        }
        filters {
          ...FilterValueFields
        }
        editLink {
          ...LinkFields
        }
        lastUpdateDate
      }
      ... on GridDataView {
        ...DataViewFields
        title
        annotation
        columns {
          ...TableColumnFields
        }
        rows {
          ...TableRowFields
        }
        filters {
          ...FilterValueFields
        }
        editLink {
          ...LinkFields
        }
        lastUpdateDate
      }
      ... on ListDataView {
        ...DataViewFields
        title
        annotation
        columns {
          ...TableColumnFields
        }
        rows {
          ...TableRowFields
        }
        filters {
          ...FilterValueFields
        }
      }
      ... on ChartDataViewResult {
        ...DataViewFields
        xAxis {
          ...ChartAxisFields
        }
        yAxis {
          ...ChartAxisFields
        }
        series {
          ...ChartSeriesFields
        }
        filters {
          ...FilterValueFields
        }
        renderer
      }
      ... on HeaderRow {
        ...DataViewFields
        columns {
          ...TableColumnFields
        }
        rows {
          ...TableRowFields
        }
      }
      ... on SummaryInfoDataView {
        ...DataViewFields
        summaryInfo {
          items {
            ...SummaryInfoItemFields
          }
        }
      }
      ... on KpiStatusOverview {
        ...KpiStatusOverviewFields
      }
      ... on DialsDataView {
        ...DataViewFields
        annotation
        annotationDate
        items {
          ...DialItemFields
        }
        lastUpdateDate
      }
      ... on RecommenderDataView {
        ...DataViewFields
        insights {
          ... on Story {
            ...StoryFields
          }
          ... on Real {
            ...RealFields
          }
        }
        seeAllLink {
          ...LinkFields
        }
      }
      ... on RecommendationsActionsDataView {
        ...RecommendationsActionsDataViewFields
      }
      ... on RecommendationsBreakdownDataView {
        ...RecommendationsBreakdownDataViewFields
      }
      ... on CardsDataView {
        ...DataViewFields
        title
        annotation
        cards {
          ... on MicroChartCardResult {
            ...MicroChartCardFields
          }
          ... on KpiCardResult {
            ...KpiCardFields
          }
          ... on SimpleCardResult {
            ...SimpleCardFields
          }
        }
        editLink {
          ...LinkFields
        }
      }
      ... on ButtonDataView {
        ...DataViewFields
        label
        filters {
          ...FilterValueFields
        }
      }
    }
  }
  ${DataViewFieldsFragmentDoc}
  ${TableColumnFieldsFragmentDoc}
  ${TableRowFieldsFragmentDoc}
  ${FilterValueFieldsFragmentDoc}
  ${LinkFieldsFragmentDoc}
  ${ChartAxisFieldsFragmentDoc}
  ${ChartSeriesFieldsFragmentDoc}
  ${SummaryInfoItemFieldsFragmentDoc}
  ${KpiStatusOverviewFieldsFragmentDoc}
  ${DialItemFieldsFragmentDoc}
  ${StoryFieldsFragmentDoc}
  ${RealFieldsFragmentDoc}
  ${RecommendationsActionsDataViewFieldsFragmentDoc}
  ${RecommendationsBreakdownDataViewFieldsFragmentDoc}
  ${MicroChartCardFieldsFragmentDoc}
  ${KpiCardFieldsFragmentDoc}
  ${SimpleCardFieldsFragmentDoc}
`;

/**
 * __useGetDataViewQuery__
 *
 * To run a query within a React component, call `useGetDataViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataViewQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDataViewQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetDataViewQuery, GetDataViewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetDataViewQuery, GetDataViewQueryVariables>(
    GetDataViewDocument,
    options,
  );
}

export function useGetDataViewLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDataViewQuery, GetDataViewQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetDataViewQuery, GetDataViewQueryVariables>(
    GetDataViewDocument,
    options,
  );
}

export function useGetDataViewSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetDataViewQuery,
    GetDataViewQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetDataViewQuery, GetDataViewQueryVariables>(
    GetDataViewDocument,
    options,
  );
}

export type GetDataViewQueryHookResult = ReturnType<typeof useGetDataViewQuery>;
export type GetDataViewLazyQueryHookResult = ReturnType<typeof useGetDataViewLazyQuery>;
export type GetDataViewSuspenseQueryHookResult = ReturnType<typeof useGetDataViewSuspenseQuery>;
export type GetDataViewQueryResult = Apollo.QueryResult<
  GetDataViewQuery,
  GetDataViewQueryVariables
>;
export const GetFilterByIdDocument = gql`
  query GetFilterById($id: Uuid!, $moduleId: Uuid) {
    filterById(id: $id, moduleId: $moduleId) {
      ...FilterFields
      ... on SelectFilter {
        ...SelectFilterFields
      }
      ... on TreeSelectFilter {
        ...TreeSelectFilterFields
      }
      ... on SelectGroupFilter {
        ...SelectGroupFilterFields
      }
    }
  }
  ${FilterFieldsFragmentDoc}
  ${SelectFilterFieldsFragmentDoc}
  ${TreeSelectFilterFieldsFragmentDoc}
  ${SelectGroupFilterFieldsFragmentDoc}
`;

/**
 * __useGetFilterByIdQuery__
 *
 * To run a query within a React component, call `useGetFilterByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilterByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilterByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useGetFilterByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetFilterByIdQuery, GetFilterByIdQueryVariables> &
    (
      | { variables: GetFilterByIdQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetFilterByIdQuery, GetFilterByIdQueryVariables>(
    GetFilterByIdDocument,
    options,
  );
}

export function useGetFilterByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFilterByIdQuery,
    GetFilterByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetFilterByIdQuery, GetFilterByIdQueryVariables>(
    GetFilterByIdDocument,
    options,
  );
}

export function useGetFilterByIdSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetFilterByIdQuery,
    GetFilterByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetFilterByIdQuery, GetFilterByIdQueryVariables>(
    GetFilterByIdDocument,
    options,
  );
}

export type GetFilterByIdQueryHookResult = ReturnType<typeof useGetFilterByIdQuery>;
export type GetFilterByIdLazyQueryHookResult = ReturnType<typeof useGetFilterByIdLazyQuery>;
export type GetFilterByIdSuspenseQueryHookResult = ReturnType<typeof useGetFilterByIdSuspenseQuery>;
export type GetFilterByIdQueryResult = Apollo.QueryResult<
  GetFilterByIdQuery,
  GetFilterByIdQueryVariables
>;
export const GetFiltersByIdDocument = gql`
  query GetFiltersById(
    $ids: [Uuid!]
    $moduleId: Uuid
    $filters: [FilterInput]
    $drillId: Long
    $drillIds: [Long!]
    $viewCode: String
  ) {
    filtersById(
      ids: $ids
      moduleId: $moduleId
      filters: $filters
      drillId: $drillId
      drillIds: $drillIds
      viewCode: $viewCode
    ) {
      ...FilterFields
      ... on SelectFilter {
        ...SelectFilterFields
      }
      ... on TreeSelectFilter {
        ...TreeSelectFilterFields
      }
      ... on SelectGroupFilter {
        ...SelectGroupFilterFields
      }
    }
  }
  ${FilterFieldsFragmentDoc}
  ${SelectFilterFieldsFragmentDoc}
  ${TreeSelectFilterFieldsFragmentDoc}
  ${SelectGroupFilterFieldsFragmentDoc}
`;

/**
 * __useGetFiltersByIdQuery__
 *
 * To run a query within a React component, call `useGetFiltersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFiltersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFiltersByIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      moduleId: // value for 'moduleId'
 *      filters: // value for 'filters'
 *      drillId: // value for 'drillId'
 *      drillIds: // value for 'drillIds'
 *      viewCode: // value for 'viewCode'
 *   },
 * });
 */
export function useGetFiltersByIdQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFiltersByIdQuery,
    GetFiltersByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetFiltersByIdQuery, GetFiltersByIdQueryVariables>(
    GetFiltersByIdDocument,
    options,
  );
}

export function useGetFiltersByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFiltersByIdQuery,
    GetFiltersByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetFiltersByIdQuery, GetFiltersByIdQueryVariables>(
    GetFiltersByIdDocument,
    options,
  );
}

export function useGetFiltersByIdSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetFiltersByIdQuery,
    GetFiltersByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetFiltersByIdQuery, GetFiltersByIdQueryVariables>(
    GetFiltersByIdDocument,
    options,
  );
}

export type GetFiltersByIdQueryHookResult = ReturnType<typeof useGetFiltersByIdQuery>;
export type GetFiltersByIdLazyQueryHookResult = ReturnType<typeof useGetFiltersByIdLazyQuery>;
export type GetFiltersByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetFiltersByIdSuspenseQuery
>;
export type GetFiltersByIdQueryResult = Apollo.QueryResult<
  GetFiltersByIdQuery,
  GetFiltersByIdQueryVariables
>;
export const GetModulesDocument = gql`
  query GetModules {
    modules {
      ...ModuleFields
    }
  }
  ${ModuleFieldsFragmentDoc}
`;

/**
 * __useGetModulesQuery__
 *
 * To run a query within a React component, call `useGetModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModulesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetModulesQuery, GetModulesQueryVariables>,
) {
  const options = { cachePolicy: 'cache-first', ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetModulesQuery, GetModulesQueryVariables>(
    GetModulesDocument,
    options,
  );
}

export function useGetModulesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetModulesQuery, GetModulesQueryVariables>,
) {
  const options = { cachePolicy: 'cache-first', ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetModulesQuery, GetModulesQueryVariables>(
    GetModulesDocument,
    options,
  );
}

export function useGetModulesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetModulesQuery,
    GetModulesQueryVariables
  >,
) {
  const options = { cachePolicy: 'cache-first', ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetModulesQuery, GetModulesQueryVariables>(
    GetModulesDocument,
    options,
  );
}

export type GetModulesQueryHookResult = ReturnType<typeof useGetModulesQuery>;
export type GetModulesLazyQueryHookResult = ReturnType<typeof useGetModulesLazyQuery>;
export type GetModulesSuspenseQueryHookResult = ReturnType<typeof useGetModulesSuspenseQuery>;
export type GetModulesQueryResult = Apollo.QueryResult<GetModulesQuery, GetModulesQueryVariables>;
export const GetPageContentDocument = gql`
  query GetPageContent($pageId: Uuid!, $priorityId: Int) {
    pageContent(pageId: $pageId, priorityId: $priorityId) {
      body {
        ... on ButtonComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on FilterComponent {
          ...FilterComponentFields
        }
        ... on NavigationTabsComponent {
          ...NavigationTabsComponentFields
        }
        ... on TableSwitcherComponent {
          ...TableSwitcherComponentFields
        }
        ... on FiltersComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on TableComponent {
          ...TableComponentFields
        }
        ... on GridComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on ListComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on ChartComponent {
          ...ComponentFields
          drillIds
          filters {
            ...FilterComponentFields
          }
        }
        ... on HeaderRowComponent {
          ...ComponentFields
          drillIds
          filters {
            ...FilterComponentFields
          }
          variant
        }
        ... on SummaryInfoComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on KpiComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on TimelineComponent {
          ...ComponentFields
        }
        ... on DialsComponent {
          ...ComponentFields
        }
        ... on StoryListComponent {
          ...ComponentFields
        }
        ... on RecommenderComponent {
          ...ComponentFields
        }
        ... on PlailistComponent {
          ...ComponentFields
        }
        ... on ColumnLayoutComponent {
          ...ComponentFields
          headerRow {
            ...ComponentFields
            ... on FiltersComponent {
              filters {
                ...FilterComponentFields
              }
            }
          }
          firstColumn {
            ...ComponentFields
            ... on HeaderRowComponent {
              filters {
                ...FilterComponentFields
              }
              variant
            }
            ... on ChartComponent {
              filters {
                ...FilterComponentFields
              }
            }
          }
          secondColumn {
            ...ComponentFields
            ... on FilterComponent {
              ...FilterComponentFields
            }
            ... on TableComponent {
              ...TableComponentFields
            }
            ... on RecommendationsActionsComponent {
              code
              id
              filters {
                ...FilterComponentFields
              }
            }
          }
        }
        ... on UserAnalyticsComponent {
          ...ComponentFields
          filters {
            ...FilterComponentFields
          }
        }
        ... on UserPrioritiesComponent {
          ...ComponentFields
        }
        ... on CardsComponent {
          ...ComponentFields
        }
        ... on RecommendationsBreakdownComponent {
          id
          componentType
          icon
          viewType
          filters {
            ...FilterComponentFields
          }
        }
      }
    }
  }
  ${ComponentFieldsFragmentDoc}
  ${FilterComponentFieldsFragmentDoc}
  ${NavigationTabsComponentFieldsFragmentDoc}
  ${TableSwitcherComponentFieldsFragmentDoc}
  ${TableComponentFieldsFragmentDoc}
`;

/**
 * __useGetPageContentQuery__
 *
 * To run a query within a React component, call `useGetPageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageContentQuery({
 *   variables: {
 *      pageId: // value for 'pageId'
 *      priorityId: // value for 'priorityId'
 *   },
 * });
 */
export function useGetPageContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPageContentQuery,
    GetPageContentQueryVariables
  > &
    (
      | { variables: GetPageContentQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { cachePolicy: 'cache-first', ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetPageContentQuery, GetPageContentQueryVariables>(
    GetPageContentDocument,
    { ...options, fetchPolicy: 'cache-first' },
  );
}

export function useGetPageContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPageContentQuery,
    GetPageContentQueryVariables
  >,
) {
  const options = { cachePolicy: 'cache-first', ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetPageContentQuery, GetPageContentQueryVariables>(
    GetPageContentDocument,
    options,
  );
}

export function useGetPageContentSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetPageContentQuery,
    GetPageContentQueryVariables
  >,
) {
  const options = { cachePolicy: 'cache-first', ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetPageContentQuery, GetPageContentQueryVariables>(
    GetPageContentDocument,
    options,
  );
}

export type GetPageContentQueryHookResult = ReturnType<typeof useGetPageContentQuery>;
export type GetPageContentLazyQueryHookResult = ReturnType<typeof useGetPageContentLazyQuery>;
export type GetPageContentSuspenseQueryHookResult = ReturnType<
  typeof useGetPageContentSuspenseQuery
>;
export type GetPageContentQueryResult = Apollo.QueryResult<
  GetPageContentQuery,
  GetPageContentQueryVariables
>;
export const GetPlailistsDocument = gql`
  query GetPlailists($input: PlailistsQueryInput!) {
    plailists(input: $input) {
      ...PlailistsResultFields
    }
  }
  ${PlailistsResultFieldsFragmentDoc}
`;

/**
 * __useGetPlailistsQuery__
 *
 * To run a query within a React component, call `useGetPlailistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlailistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlailistsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPlailistsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetPlailistsQuery, GetPlailistsQueryVariables> &
    (
      | { variables: GetPlailistsQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetPlailistsQuery, GetPlailistsQueryVariables>(
    GetPlailistsDocument,
    options,
  );
}

export function useGetPlailistsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPlailistsQuery,
    GetPlailistsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetPlailistsQuery, GetPlailistsQueryVariables>(
    GetPlailistsDocument,
    options,
  );
}

export function useGetPlailistsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetPlailistsQuery,
    GetPlailistsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetPlailistsQuery, GetPlailistsQueryVariables>(
    GetPlailistsDocument,
    options,
  );
}

export type GetPlailistsQueryHookResult = ReturnType<typeof useGetPlailistsQuery>;
export type GetPlailistsLazyQueryHookResult = ReturnType<typeof useGetPlailistsLazyQuery>;
export type GetPlailistsSuspenseQueryHookResult = ReturnType<typeof useGetPlailistsSuspenseQuery>;
export type GetPlailistsQueryResult = Apollo.QueryResult<
  GetPlailistsQuery,
  GetPlailistsQueryVariables
>;
export const GetPrioritiesDocument = gql`
  query GetPriorities($input: PrioritiesInput!) {
    priorities(input: $input) {
      ...PriorityCategoryFields
    }
  }
  ${PriorityCategoryFieldsFragmentDoc}
`;

/**
 * __useGetPrioritiesQuery__
 *
 * To run a query within a React component, call `useGetPrioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrioritiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPrioritiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetPrioritiesQuery, GetPrioritiesQueryVariables> &
    (
      | { variables: GetPrioritiesQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetPrioritiesQuery, GetPrioritiesQueryVariables>(
    GetPrioritiesDocument,
    options,
  );
}

export function useGetPrioritiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPrioritiesQuery,
    GetPrioritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetPrioritiesQuery, GetPrioritiesQueryVariables>(
    GetPrioritiesDocument,
    options,
  );
}

export function useGetPrioritiesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetPrioritiesQuery,
    GetPrioritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetPrioritiesQuery, GetPrioritiesQueryVariables>(
    GetPrioritiesDocument,
    options,
  );
}

export type GetPrioritiesQueryHookResult = ReturnType<typeof useGetPrioritiesQuery>;
export type GetPrioritiesLazyQueryHookResult = ReturnType<typeof useGetPrioritiesLazyQuery>;
export type GetPrioritiesSuspenseQueryHookResult = ReturnType<typeof useGetPrioritiesSuspenseQuery>;
export type GetPrioritiesQueryResult = Apollo.QueryResult<
  GetPrioritiesQuery,
  GetPrioritiesQueryVariables
>;
export const GetPriorityFilterDocument = gql`
  query GetPriorityFilter($moduleId: Uuid!) {
    priorityFilter(moduleId: $moduleId) {
      ...FilterFields
      ... on SelectFilter {
        ...SelectFilterFields
      }
      ... on TreeSelectFilter {
        ...TreeSelectFilterFields
      }
    }
  }
  ${FilterFieldsFragmentDoc}
  ${SelectFilterFieldsFragmentDoc}
  ${TreeSelectFilterFieldsFragmentDoc}
`;

/**
 * __useGetPriorityFilterQuery__
 *
 * To run a query within a React component, call `useGetPriorityFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPriorityFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPriorityFilterQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useGetPriorityFilterQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPriorityFilterQuery,
    GetPriorityFilterQueryVariables
  > &
    (
      | { variables: GetPriorityFilterQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetPriorityFilterQuery, GetPriorityFilterQueryVariables>(
    GetPriorityFilterDocument,
    options,
  );
}

export function useGetPriorityFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPriorityFilterQuery,
    GetPriorityFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetPriorityFilterQuery, GetPriorityFilterQueryVariables>(
    GetPriorityFilterDocument,
    options,
  );
}

export function useGetPriorityFilterSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetPriorityFilterQuery,
    GetPriorityFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetPriorityFilterQuery, GetPriorityFilterQueryVariables>(
    GetPriorityFilterDocument,
    options,
  );
}

export type GetPriorityFilterQueryHookResult = ReturnType<typeof useGetPriorityFilterQuery>;
export type GetPriorityFilterLazyQueryHookResult = ReturnType<typeof useGetPriorityFilterLazyQuery>;
export type GetPriorityFilterSuspenseQueryHookResult = ReturnType<
  typeof useGetPriorityFilterSuspenseQuery
>;
export type GetPriorityFilterQueryResult = Apollo.QueryResult<
  GetPriorityFilterQuery,
  GetPriorityFilterQueryVariables
>;
export const GetStoriesDocument = gql`
  query GetStories($input: StoriesQueryInput!) {
    stories(input: $input) {
      ...StoryFields
      ...RealFields
    }
  }
  ${StoryFieldsFragmentDoc}
  ${RealFieldsFragmentDoc}
`;

/**
 * __useGetStoriesQuery__
 *
 * To run a query within a React component, call `useGetStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetStoriesQuery, GetStoriesQueryVariables> &
    (
      | { variables: GetStoriesQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetStoriesQuery, GetStoriesQueryVariables>(
    GetStoriesDocument,
    options,
  );
}

export function useGetStoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStoriesQuery, GetStoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetStoriesQuery, GetStoriesQueryVariables>(
    GetStoriesDocument,
    options,
  );
}

export function useGetStoriesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetStoriesQuery,
    GetStoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetStoriesQuery, GetStoriesQueryVariables>(
    GetStoriesDocument,
    options,
  );
}

export type GetStoriesQueryHookResult = ReturnType<typeof useGetStoriesQuery>;
export type GetStoriesLazyQueryHookResult = ReturnType<typeof useGetStoriesLazyQuery>;
export type GetStoriesSuspenseQueryHookResult = ReturnType<typeof useGetStoriesSuspenseQuery>;
export type GetStoriesQueryResult = Apollo.QueryResult<GetStoriesQuery, GetStoriesQueryVariables>;
export const GetStoriesByPlailistDocument = gql`
  query GetStoriesByPlailist($input: PlailistStoriesInput!) {
    storiesByPlailist(input: $input) {
      ...StoryFields
    }
  }
  ${StoryFieldsFragmentDoc}
`;

/**
 * __useGetStoriesByPlailistQuery__
 *
 * To run a query within a React component, call `useGetStoriesByPlailistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoriesByPlailistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoriesByPlailistQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoriesByPlailistQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetStoriesByPlailistQuery,
    GetStoriesByPlailistQueryVariables
  > &
    (
      | { variables: GetStoriesByPlailistQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetStoriesByPlailistQuery, GetStoriesByPlailistQueryVariables>(
    GetStoriesByPlailistDocument,
    options,
  );
}

export function useGetStoriesByPlailistLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetStoriesByPlailistQuery,
    GetStoriesByPlailistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetStoriesByPlailistQuery,
    GetStoriesByPlailistQueryVariables
  >(GetStoriesByPlailistDocument, options);
}

export function useGetStoriesByPlailistSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetStoriesByPlailistQuery,
    GetStoriesByPlailistQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetStoriesByPlailistQuery,
    GetStoriesByPlailistQueryVariables
  >(GetStoriesByPlailistDocument, options);
}

export type GetStoriesByPlailistQueryHookResult = ReturnType<typeof useGetStoriesByPlailistQuery>;
export type GetStoriesByPlailistLazyQueryHookResult = ReturnType<
  typeof useGetStoriesByPlailistLazyQuery
>;
export type GetStoriesByPlailistSuspenseQueryHookResult = ReturnType<
  typeof useGetStoriesByPlailistSuspenseQuery
>;
export type GetStoriesByPlailistQueryResult = Apollo.QueryResult<
  GetStoriesByPlailistQuery,
  GetStoriesByPlailistQueryVariables
>;
export const GetStoryByIdDocument = gql`
  query GetStoryById($storyId: Uuid!) {
    storyById(storyId: $storyId) {
      ...StoryFields
    }
  }
  ${StoryFieldsFragmentDoc}
`;

/**
 * __useGetStoryByIdQuery__
 *
 * To run a query within a React component, call `useGetStoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoryByIdQuery({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useGetStoryByIdQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetStoryByIdQuery, GetStoryByIdQueryVariables> &
    (
      | { variables: GetStoryByIdQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetStoryByIdQuery, GetStoryByIdQueryVariables>(
    GetStoryByIdDocument,
    options,
  );
}

export function useGetStoryByIdLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetStoryByIdQuery,
    GetStoryByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetStoryByIdQuery, GetStoryByIdQueryVariables>(
    GetStoryByIdDocument,
    options,
  );
}

export function useGetStoryByIdSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetStoryByIdQuery,
    GetStoryByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetStoryByIdQuery, GetStoryByIdQueryVariables>(
    GetStoryByIdDocument,
    options,
  );
}

export type GetStoryByIdQueryHookResult = ReturnType<typeof useGetStoryByIdQuery>;
export type GetStoryByIdLazyQueryHookResult = ReturnType<typeof useGetStoryByIdLazyQuery>;
export type GetStoryByIdSuspenseQueryHookResult = ReturnType<typeof useGetStoryByIdSuspenseQuery>;
export type GetStoryByIdQueryResult = Apollo.QueryResult<
  GetStoryByIdQuery,
  GetStoryByIdQueryVariables
>;
export const GetUserAnalyticsDocument = gql`
  query GetUserAnalytics($input: UserAnalyticsInput!) {
    userAnalytics(input: $input) {
      headerMetrics {
        ...MetricFields
      }
      metricLists {
        ...MetricListFields
      }
      timeFrame
    }
  }
  ${MetricFieldsFragmentDoc}
  ${MetricListFieldsFragmentDoc}
`;

/**
 * __useGetUserAnalyticsQuery__
 *
 * To run a query within a React component, call `useGetUserAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAnalyticsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserAnalyticsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetUserAnalyticsQuery,
    GetUserAnalyticsQueryVariables
  > &
    (
      | { variables: GetUserAnalyticsQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetUserAnalyticsQuery, GetUserAnalyticsQueryVariables>(
    GetUserAnalyticsDocument,
    options,
  );
}

export function useGetUserAnalyticsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserAnalyticsQuery,
    GetUserAnalyticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetUserAnalyticsQuery, GetUserAnalyticsQueryVariables>(
    GetUserAnalyticsDocument,
    options,
  );
}

export function useGetUserAnalyticsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetUserAnalyticsQuery,
    GetUserAnalyticsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetUserAnalyticsQuery, GetUserAnalyticsQueryVariables>(
    GetUserAnalyticsDocument,
    options,
  );
}

export type GetUserAnalyticsQueryHookResult = ReturnType<typeof useGetUserAnalyticsQuery>;
export type GetUserAnalyticsLazyQueryHookResult = ReturnType<typeof useGetUserAnalyticsLazyQuery>;
export type GetUserAnalyticsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserAnalyticsSuspenseQuery
>;
export type GetUserAnalyticsQueryResult = Apollo.QueryResult<
  GetUserAnalyticsQuery,
  GetUserAnalyticsQueryVariables
>;
export const GetUserPrioritiesDocument = gql`
  query GetUserPriorities($input: PrioritiesInput!) {
    userPriorities(input: $input) {
      ...PriorityCategoryFields
    }
  }
  ${PriorityCategoryFieldsFragmentDoc}
`;

/**
 * __useGetUserPrioritiesQuery__
 *
 * To run a query within a React component, call `useGetUserPrioritiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPrioritiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPrioritiesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserPrioritiesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetUserPrioritiesQuery,
    GetUserPrioritiesQueryVariables
  > &
    (
      | { variables: GetUserPrioritiesQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetUserPrioritiesQuery, GetUserPrioritiesQueryVariables>(
    GetUserPrioritiesDocument,
    options,
  );
}

export function useGetUserPrioritiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserPrioritiesQuery,
    GetUserPrioritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetUserPrioritiesQuery, GetUserPrioritiesQueryVariables>(
    GetUserPrioritiesDocument,
    options,
  );
}

export function useGetUserPrioritiesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetUserPrioritiesQuery,
    GetUserPrioritiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetUserPrioritiesQuery, GetUserPrioritiesQueryVariables>(
    GetUserPrioritiesDocument,
    options,
  );
}

export type GetUserPrioritiesQueryHookResult = ReturnType<typeof useGetUserPrioritiesQuery>;
export type GetUserPrioritiesLazyQueryHookResult = ReturnType<typeof useGetUserPrioritiesLazyQuery>;
export type GetUserPrioritiesSuspenseQueryHookResult = ReturnType<
  typeof useGetUserPrioritiesSuspenseQuery
>;
export type GetUserPrioritiesQueryResult = Apollo.QueryResult<
  GetUserPrioritiesQuery,
  GetUserPrioritiesQueryVariables
>;
export const GetUserSettingsDocument = gql`
  query GetUserSettings($input: GetUserSettingsInput!) {
    userSettings(input: $input) {
      ...UserSettingFields
    }
  }
  ${UserSettingFieldsFragmentDoc}
`;

/**
 * __useGetUserSettingsQuery__
 *
 * To run a query within a React component, call `useGetUserSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSettingsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserSettingsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetUserSettingsQuery,
    GetUserSettingsQueryVariables
  > &
    (
      | { variables: GetUserSettingsQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(
    GetUserSettingsDocument,
    options,
  );
}

export function useGetUserSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserSettingsQuery,
    GetUserSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(
    GetUserSettingsDocument,
    options,
  );
}

export function useGetUserSettingsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetUserSettingsQuery,
    GetUserSettingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetUserSettingsQuery, GetUserSettingsQueryVariables>(
    GetUserSettingsDocument,
    options,
  );
}

export type GetUserSettingsQueryHookResult = ReturnType<typeof useGetUserSettingsQuery>;
export type GetUserSettingsLazyQueryHookResult = ReturnType<typeof useGetUserSettingsLazyQuery>;
export type GetUserSettingsSuspenseQueryHookResult = ReturnType<
  typeof useGetUserSettingsSuspenseQuery
>;
export type GetUserSettingsQueryResult = Apollo.QueryResult<
  GetUserSettingsQuery,
  GetUserSettingsQueryVariables
>;
export const GetDimensionFilterDocument = gql`
  query GetDimensionFilter($moduleId: Uuid!, $priorityId: Int!) {
    dimensionFilter(moduleId: $moduleId, priorityId: $priorityId) {
      ...FilterFields
      ... on SelectFilter {
        ...SelectFilterFields
        __typename
      }
      ... on TreeSelectFilter {
        ...TreeSelectMDFilterFields
        __typename
      }
      __typename
    }
  }
  ${FilterFieldsFragmentDoc}
  ${SelectFilterFieldsFragmentDoc}
  ${TreeSelectMdFilterFieldsFragmentDoc}
`;

/**
 * __useGetDimensionFilterQuery__
 *
 * To run a query within a React component, call `useGetDimensionFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDimensionFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDimensionFilterQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      priorityId: // value for 'priorityId'
 *   },
 * });
 */
export function useGetDimensionFilterQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetDimensionFilterQuery,
    GetDimensionFilterQueryVariables
  > &
    (
      | { variables: GetDimensionFilterQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetDimensionFilterQuery, GetDimensionFilterQueryVariables>(
    GetDimensionFilterDocument,
    options,
  );
}

export function useGetDimensionFilterLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDimensionFilterQuery,
    GetDimensionFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetDimensionFilterQuery, GetDimensionFilterQueryVariables>(
    GetDimensionFilterDocument,
    options,
  );
}

export function useGetDimensionFilterSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetDimensionFilterQuery,
    GetDimensionFilterQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetDimensionFilterQuery,
    GetDimensionFilterQueryVariables
  >(GetDimensionFilterDocument, options);
}

export type GetDimensionFilterQueryHookResult = ReturnType<typeof useGetDimensionFilterQuery>;
export type GetDimensionFilterLazyQueryHookResult = ReturnType<
  typeof useGetDimensionFilterLazyQuery
>;
export type GetDimensionFilterSuspenseQueryHookResult = ReturnType<
  typeof useGetDimensionFilterSuspenseQuery
>;
export type GetDimensionFilterQueryResult = Apollo.QueryResult<
  GetDimensionFilterQuery,
  GetDimensionFilterQueryVariables
>;
export const GetInboxDocument = gql`
  query GetInbox {
    inbox {
      messages {
        sharedLinks {
          id
          title
          subtitle
          moduleCode
          sharedBy
          link
          isFollowed
          contentType
          isExpired
          sentAt
          tag {
            type
            message
            isDefault
          }
        }
      }
      decisions {
        title
        contentType
        isExpired
      }
      yourBrain {
        resourceId
        title
        contentType
        moduleCode
        link
        createdAt
        isExpired
      }
    }
  }
`;

/**
 * __useGetInboxQuery__
 *
 * To run a query within a React component, call `useGetInboxQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboxQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboxQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboxQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetInboxQuery, GetInboxQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetInboxQuery, GetInboxQueryVariables>(
    GetInboxDocument,
    options,
  );
}

export function useGetInboxLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInboxQuery, GetInboxQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetInboxQuery, GetInboxQueryVariables>(
    GetInboxDocument,
    options,
  );
}

export function useGetInboxSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<GetInboxQuery, GetInboxQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetInboxQuery, GetInboxQueryVariables>(
    GetInboxDocument,
    options,
  );
}

export type GetInboxQueryHookResult = ReturnType<typeof useGetInboxQuery>;
export type GetInboxLazyQueryHookResult = ReturnType<typeof useGetInboxLazyQuery>;
export type GetInboxSuspenseQueryHookResult = ReturnType<typeof useGetInboxSuspenseQuery>;
export type GetInboxQueryResult = Apollo.QueryResult<GetInboxQuery, GetInboxQueryVariables>;
export const GetDimensionsDocument = gql`
  query GetDimensions($input: DimensionsInput!) {
    dimensions(input: $input) {
      ...DimensionResultFragment
    }
  }
  ${DimensionResultFragmentFragmentDoc}
`;

/**
 * __useGetDimensionsQuery__
 *
 * To run a query within a React component, call `useGetDimensionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDimensionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDimensionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDimensionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<GetDimensionsQuery, GetDimensionsQueryVariables> &
    (
      | { variables: GetDimensionsQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetDimensionsQuery, GetDimensionsQueryVariables>(
    GetDimensionsDocument,
    options,
  );
}

export function useGetDimensionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetDimensionsQuery,
    GetDimensionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetDimensionsQuery, GetDimensionsQueryVariables>(
    GetDimensionsDocument,
    options,
  );
}

export function useGetDimensionsSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetDimensionsQuery,
    GetDimensionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<GetDimensionsQuery, GetDimensionsQueryVariables>(
    GetDimensionsDocument,
    options,
  );
}

export type GetDimensionsQueryHookResult = ReturnType<typeof useGetDimensionsQuery>;
export type GetDimensionsLazyQueryHookResult = ReturnType<typeof useGetDimensionsLazyQuery>;
export type GetDimensionsSuspenseQueryHookResult = ReturnType<typeof useGetDimensionsSuspenseQuery>;
export type GetDimensionsQueryResult = Apollo.QueryResult<
  GetDimensionsQuery,
  GetDimensionsQueryVariables
>;
export const GetPlailistStoriesDocument = gql`
  query GetPlailistStories($input: PlailistStoriesInput!) {
    storiesByPlailist(input: $input) {
      ...StoryFields
    }
  }
  ${StoryFieldsFragmentDoc}
`;

/**
 * __useGetPlailistStoriesQuery__
 *
 * To run a query within a React component, call `useGetPlailistStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlailistStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlailistStoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPlailistStoriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetPlailistStoriesQuery,
    GetPlailistStoriesQueryVariables
  > &
    (
      | { variables: GetPlailistStoriesQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetPlailistStoriesQuery, GetPlailistStoriesQueryVariables>(
    GetPlailistStoriesDocument,
    options,
  );
}

export function useGetPlailistStoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPlailistStoriesQuery,
    GetPlailistStoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<GetPlailistStoriesQuery, GetPlailistStoriesQueryVariables>(
    GetPlailistStoriesDocument,
    options,
  );
}

export function useGetPlailistStoriesSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetPlailistStoriesQuery,
    GetPlailistStoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetPlailistStoriesQuery,
    GetPlailistStoriesQueryVariables
  >(GetPlailistStoriesDocument, options);
}

export type GetPlailistStoriesQueryHookResult = ReturnType<typeof useGetPlailistStoriesQuery>;
export type GetPlailistStoriesLazyQueryHookResult = ReturnType<
  typeof useGetPlailistStoriesLazyQuery
>;
export type GetPlailistStoriesSuspenseQueryHookResult = ReturnType<
  typeof useGetPlailistStoriesSuspenseQuery
>;
export type GetPlailistStoriesQueryResult = Apollo.QueryResult<
  GetPlailistStoriesQuery,
  GetPlailistStoriesQueryVariables
>;
export const GetSupportPageContentDocument = gql`
  query GetSupportPageContent($pageId: Uuid!, $priorityId: Int) {
    pageContent(pageId: $pageId, priorityId: $priorityId) {
      body {
        ... on SupportComponent {
          ...SupportComponentFields
          settings {
            ...SupportComponentSettingsFields
          }
        }
      }
    }
  }
  ${SupportComponentFieldsFragmentDoc}
  ${SupportComponentSettingsFieldsFragmentDoc}
`;

/**
 * __useGetSupportPageContentQuery__
 *
 * To run a query within a React component, call `useGetSupportPageContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportPageContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportPageContentQuery({
 *   variables: {
 *      pageId: // value for 'pageId'
 *      priorityId: // value for 'priorityId'
 *   },
 * });
 */
export function useGetSupportPageContentQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetSupportPageContentQuery,
    GetSupportPageContentQueryVariables
  > &
    (
      | { variables: GetSupportPageContentQueryVariables; skip?: boolean }
      | {
          skip: boolean;
        }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<GetSupportPageContentQuery, GetSupportPageContentQueryVariables>(
    GetSupportPageContentDocument,
    options,
  );
}

export function useGetSupportPageContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSupportPageContentQuery,
    GetSupportPageContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetSupportPageContentQuery,
    GetSupportPageContentQueryVariables
  >(GetSupportPageContentDocument, options);
}

export function useGetSupportPageContentSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    GetSupportPageContentQuery,
    GetSupportPageContentQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    GetSupportPageContentQuery,
    GetSupportPageContentQueryVariables
  >(GetSupportPageContentDocument, options);
}

export type GetSupportPageContentQueryHookResult = ReturnType<typeof useGetSupportPageContentQuery>;
export type GetSupportPageContentLazyQueryHookResult = ReturnType<
  typeof useGetSupportPageContentLazyQuery
>;
export type GetSupportPageContentSuspenseQueryHookResult = ReturnType<
  typeof useGetSupportPageContentSuspenseQuery
>;
export type GetSupportPageContentQueryResult = Apollo.QueryResult<
  GetSupportPageContentQuery,
  GetSupportPageContentQueryVariables
>;
export const SiteConfigurationDocument = gql`
  query SiteConfiguration {
    siteConfiguration {
      version
    }
  }
`;

/**
 * __useSiteConfigurationQuery__
 *
 * To run a query within a React component, call `useSiteConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useSiteConfigurationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    SiteConfigurationQuery,
    SiteConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<SiteConfigurationQuery, SiteConfigurationQueryVariables>(
    SiteConfigurationDocument,
    options,
  );
}

export function useSiteConfigurationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SiteConfigurationQuery,
    SiteConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<SiteConfigurationQuery, SiteConfigurationQueryVariables>(
    SiteConfigurationDocument,
    options,
  );
}

export function useSiteConfigurationSuspenseQuery(
  baseOptions?: ApolloReactHooks.SuspenseQueryHookOptions<
    SiteConfigurationQuery,
    SiteConfigurationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<SiteConfigurationQuery, SiteConfigurationQueryVariables>(
    SiteConfigurationDocument,
    options,
  );
}

export type SiteConfigurationQueryHookResult = ReturnType<typeof useSiteConfigurationQuery>;
export type SiteConfigurationLazyQueryHookResult = ReturnType<typeof useSiteConfigurationLazyQuery>;
export type SiteConfigurationSuspenseQueryHookResult = ReturnType<
  typeof useSiteConfigurationSuspenseQuery
>;
export type SiteConfigurationQueryResult = Apollo.QueryResult<
  SiteConfigurationQuery,
  SiteConfigurationQueryVariables
>;
